import React from "react";
import BaseComponent from "../../BaseComponent.js";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import Title from "../../Blocks/Titles/Title.js";
import Card from "../../Blocks/Cards/Card.js";
import SmallCardPack from "../../Blocks/Cards/SmallCardPack.js";

// IMAGES
import { ReactComponent as Illustration } from "../../Global/images/illustrationPacksRH.svg";
import { ReactComponent as BackgroundElement } from "../../Global/images/bckVideo1.svg";

const StyledPacksBlock = styled(NanoFlex)`
  height: auto;
  .packBlockWrapper {
    padding: 40px 32px;
    position: relative;
    background-color: ${(props) => props.theme.color.bluePalette.lighterBlue};
    .packsContainer {
      width: auto;
      .headerWrapper {
        margin-bottom: 24px;
      }
      .packsList {
        width: auto;
        .illustrationWrapper {
          svg {
            max-height: 450px;
          }
        }
        .cardContainer {
          width: 280px;
          margin: 16px;
          position: relative;
          a {
            text-decoration: none;
          }
        }
      }
      .mobileList {
        .cardContainer {
          width: auto;
          margin: 8px;
        }
      }
    }
  }
`;

class PacksBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledPacksBlock id="packs">
        <NanoFlex className="packBlockWrapper">
          <NanoFlex className="packsContainer" flexDirection={"column"}>
            {!this._isMobile && (
              <NanoFlex className="packsWrapper">
                <NanoFlex className="illustrationWrapper">
                  <Illustration />
                </NanoFlex>
                <NanoFlex className="packsListWrapper" flexDirection={"column"}>
                  <NanoFlex className="headerWrapper" justifyContent={"flex-end"}>
                    <Title right blue title="Faça Log" subtitle="NUMA DAS NOSSAS OFERTAS" />
                  </NanoFlex>
                  <NanoFlex className="packsList">
                    <NanoFlex className="cardContainer">
                      {/* <Link to="/premium"> */}
                      <Card comingSoon pack={this.translation.homepage.packs.premium.title} info={this.translation.homepage.packs.premium.description} />
                      {/* </Link> */}
                    </NanoFlex>
                    <NanoFlex className="cardContainer">
                      {/* <Link to="/gp"> */}
                      <Card comingSoon pack={this.translation.homepage.packs.pm.title} info={this.translation.homepage.packs.pm.description} />
                      {/* </Link> */}
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            )}
            {this._isMobile && (
              <NanoFlex className="packsListWrapper" flexDirection={"column"}>
                <NanoFlex className="headerWrapper" justifyContent={"flex-end"}>
                  <Title right blue title="Faça Log" subtitle="NUMA DAS NOSSAS OFERTAS" />
                </NanoFlex>
                <NanoFlex className="packsList mobileList" flexWrap={"wrap"}>
                  <NanoFlex className="cardContainer">
                    {/* <Link to="/premium"> */}
                    <SmallCardPack comingSoon pack={this.translation.homepage.packs.premium.title} />
                    {/* </Link> */}
                  </NanoFlex>

                  <NanoFlex className="cardContainer">
                    {/* <Link to="/gp"> */}
                    <SmallCardPack comingSoon pack={this.translation.homepage.packs.pm.title} />
                    {/* </Link> */}
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            )}
          </NanoFlex>
        </NanoFlex>
      </StyledPacksBlock>
    );
  }
}

export default PacksBlock;

// --- // Documentation // --- //
/*
  import PacksBlock from './PacksBlock.js';
  
  <PacksBlock />
  */
