import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";
import Type from "../Global/Typography.js";

const SwitchStyled = styled(NanoFlex)`
  .switchContainer {
    .labelWrapper {
      width: auto;
      p {
        text-transform: uppercase;
      }
    }
    .leftLabel {
      p {
        font-weight: bold;
      }
    }
    .rightLabel {
      p {
        font-weight: normal;
      }
    }
    .switchButton {
      height: 24px;
      width: 44px;
      margin: 0 8px;
      position: relative;
      border-radius: 24px;
      background-color: ${(props) => props.theme.color.orangePalette.orange};
      padding: 4px;
      cursor: pointer;
      transition: ${(props) => props.theme.transition};
      .switch {
        height: 16px;
        width: 16px;
        border-radius: 100%;
        position: absolute;
        left: 4px;
        top: 4px;
        background-color: ${(props) => props.theme.color.greyPalette.white};
        transition: ${(props) => props.theme.transition};
      }
    }
  }

  .switched {
    .leftLabel {
      p {
        font-weight: normal;
      }
    }
    .rightLabel {
      p {
        font-weight: bold;
      }
    }
    .switchButton {
      background-color: ${(props) => props.theme.color.bluePalette.blue};
      transition: ${(props) => props.theme.transition};
      .switch {
        left: unset;
        right: 4px;
        transition: ${(props) => props.theme.transition};
      }
    }
  }
`;

class Switch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked ?? true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.checked !== prevState.checked && this.props.onChangeAction) this.props.onChangeAction(this.state.checked);
  }

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  render() {
    return (
      <SwitchStyled>
        <NanoFlex className={`switchContainer ${this.state.checked ? "switched" : ""}`} onClick={this.toggleChecked}>
          {this.props.leftLabel && (
            <NanoFlex className="labelWrapper leftLabel" justifyContent="flex-end">
              <Type.p>{this.props.leftLabel}</Type.p>
            </NanoFlex>
          )}
          <NanoFlex className="switchButton">
            <NanoFlex className="switch"></NanoFlex>
          </NanoFlex>
          {this.props.rightLabel && (
            <NanoFlex className="labelWrapper rightLabel" justifyContent="flex-start">
              <Type.p>{this.props.rightLabel}</Type.p>
            </NanoFlex>
          )}
        </NanoFlex>
      </SwitchStyled>
    );
  }
}

// Set default props
Switch.defaultProps = {
  leftLabel: "",
  rightLabel: "",
};

export default Switch;

/*
    Doc
    import Switch from './Buttons/Switch.js';
    
    <Switch/>
    */
