import React from "react";
import { Route } from "react-router-dom";
import { useEffect } from "react";

const RenderRoute = ({ children, title, ...rest }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return <Route {...rest} render={(props) => React.cloneElement(children, { render: props })} />;
};

export default RenderRoute;
