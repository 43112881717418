import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import Switch from "../../Buttons/Switch.js";
import CardPrice from "../../Blocks/Cards/CardPrice.js";

// IMAGES
import Candidates from "../../Global/icons/darkIcons/candidates.svg";
import CandidatesYellow from "../../Global/icons/darkIcons/candidatesYellow.svg";
import Clients from "../../Global/icons/darkIcons/clients.svg";
import ClientsYellow from "../../Global/icons/darkIcons/clientsYellow.svg";
import ClientsOrange from "../../Global/icons/darkIcons/clientsOrange.svg";
import Consultants from "../../Global/icons/darkIcons/consultants.svg";
import ConsultantsYellow from "../../Global/icons/darkIcons/consultantsYellow.svg";
import ConsultantsOrange from "../../Global/icons/darkIcons/consultantsOrange.svg";
import Expenses from "../../Global/icons/darkIcons/expenses.svg";
import Projects from "../../Global/icons/darkIcons/projects.svg";
import ProjectsOrange from "../../Global/icons/darkIcons/projectsOrange.svg";
import RecruitmentRequests from "../../Global/icons/darkIcons/recruitmentRequests.svg";
import RecruitmentRequestsYellow from "../../Global/icons/darkIcons/recruitmentRequestsYellow.svg";
import Reports from "../../Global/icons/darkIcons/reports.svg";
import ReportsYellow from "../../Global/icons/darkIcons/reportsYellow.svg";
import ReportsOrange from "../../Global/icons/darkIcons/reportsOrange.svg";
import Schedule from "../../Global/icons/darkIcons/schedule.svg";
import ScheduleYellow from "../../Global/icons/darkIcons/scheduleYellow.svg";
import ScheduleOrange from "../../Global/icons/darkIcons/scheduleOrange.svg";
import Timesheets from "../../Global/icons/darkIcons/timesheets.svg";
import TimesheetsOrange from "../../Global/icons/darkIcons/timesheetsOrange.svg";

//Import Pack Values
import PacksValues from "./PacksValues.json";

const StyledPacksBlock = styled(NanoFlex)`
  height: auto;
  padding: 32px;
  .switchWrapper {
    margin-bottom: 24px;
    .observationWrapper {
      margin-top: 16px;
      height: 18px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 874px) {
    .cardsPricingWrapper {
      flex-direction: column;
    }
  }
`;

class PacksBlock extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      checked: true,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    let period = this.state.checked ? "year" : "month";
    return (
      <StyledPacksBlock>
        <NanoFlex className="packBlockPricingContainer" flexDirection="column">
          <NanoFlex className="switchWrapper" flexDirection="column">
            <Switch
              leftLabel="Mensal"
              rightLabel="Anual*"
              checked={this.state.checked}
              onChangeAction={(val) => {
                this.setState({ checked: val });
              }}
            />

            <NanoFlex className="observationWrapper">{this.state.checked && <Type.p>*O contrato anual inclui um desconto de 15%, o equivalente a 2 meses gratuitos.</Type.p>}</NanoFlex>
          </NanoFlex>

          <NanoFlex className="cardsPricingWrapper" alignItems={"stretch"}>
            <CardPrice
              comingSoon
              // blue
              packLink="/premium"
              data={{
                pack: "Premium",
                oldTopPrice: PacksValues.premium.topPrice[period].oldPrice + "€",
                newTopPrice: PacksValues.premium.topPrice[period].newPrice + "€",
                subtitleTopPrice: PacksValues.premium.topPrice.subtitle,
                oldBottomPrice: PacksValues.premium.bottomPrice[period].oldPrice + "€",
                newBottomPrice: PacksValues.premium.bottomPrice[period].newPrice + "€",
                subtitleBottomPrice: PacksValues.premium.bottomPrice.subtitle,
                features: [
                  {
                    icon: Reports,
                    name: "Reports",
                  },
                  {
                    icon: Candidates,
                    name: "Candidatos",
                  },
                  {
                    icon: Consultants,
                    name: "Colaboradores",
                  },
                  {
                    icon: Clients,
                    name: "Clientes",
                  },
                  {
                    icon: RecruitmentRequests,
                    name: "Oportunidades",
                  },
                  {
                    icon: Projects,
                    name: "Projectos",
                  },
                  {
                    icon: Schedule,
                    name: "Calendário",
                  },
                  {
                    icon: Expenses,
                    name: "Despesas",
                  },
                  {
                    icon: Timesheets,
                    name: "Timesheets",
                  },
                ],
              }}
            />
            <CardPrice
              yellow
              packLink="/rh"
              data={{
                pack: "rh",
                oldTopPrice: PacksValues.rh.topPrice[period].oldPrice + "€",
                newTopPrice: PacksValues.rh.topPrice[period].newPrice + "€",
                subtitleTopPrice: PacksValues.rh.topPrice.subtitle,
                oldBottomPrice: PacksValues.rh.bottomPrice[period].oldPrice + "€",
                newBottomPrice: PacksValues.rh.bottomPrice[period].newPrice + "€",
                subtitleBottomPrice: PacksValues.rh.bottomPrice.subtitle,
                features: [
                  {
                    icon: ReportsYellow,
                    name: "Reports",
                  },
                  {
                    icon: CandidatesYellow,
                    name: "Candidatos",
                  },
                  {
                    icon: ConsultantsYellow,
                    name: "Colaboradores",
                  },
                  {
                    icon: ClientsYellow,
                    name: "Clientes",
                  },
                  {
                    icon: RecruitmentRequestsYellow,
                    name: "Oportunidades",
                  },
                  {
                    icon: ScheduleYellow,
                    name: "Calendário",
                  },
                ],
                featuresObservation: "Podem ser adicionadas novas features que não estejam incluídas no Pack.",
              }}
            />
            <CardPrice
              comingSoon
              // orange
              packLink="/gp"
              data={{
                pack: "gp",
                oldTopPrice: PacksValues.gp.topPrice[period].oldPrice + "€",
                newTopPrice: PacksValues.gp.topPrice[period].newPrice + "€",
                subtitleTopPrice: PacksValues.gp.topPrice.subtitle,
                oldBottomPrice: PacksValues.gp.bottomPrice[period].oldPrice + "€",
                newBottomPrice: PacksValues.gp.bottomPrice[period].newPrice + "€",
                subtitleBottomPrice: PacksValues.gp.bottomPrice.subtitle,
                features: [
                  {
                    icon: ReportsOrange,
                    name: "Reports",
                  },
                  {
                    icon: ConsultantsOrange,
                    name: "Colaboradores",
                  },
                  {
                    icon: ClientsOrange,
                    name: "Clientes",
                  },
                  {
                    icon: ProjectsOrange,
                    name: "Projectos",
                  },
                  {
                    icon: ScheduleOrange,
                    name: "Calendário",
                  },
                  {
                    icon: TimesheetsOrange,
                    name: "Timesheets",
                  },
                ],
                featuresObservation: "Podem ser adicionadas novas features que não estejam incluídas no Pack.",
              }}
            />
          </NanoFlex>
        </NanoFlex>
      </StyledPacksBlock>
    );
  }
}

export default PacksBlock;

// --- // Documentation // --- //
/*
    import PacksBlock from './PacksBlock.js';
    
    <PacksBlock />
    */
