import React from "react";
import { NavLink, Link } from "react-router-dom";
import ScrollspyNav from "react-scrollspy-nav";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// IMAGES
import { ReactComponent as Twitter } from "../../Global/icons/twitter.svg";
import { ReactComponent as Linkedin } from "../../Global/icons/linkedin.svg";

const StyledMenuMobile = styled(NanoFlex)`
  display: ${(props) => (props.show ? "flex" : "none")};
  z-index: 7;
  background: ${(props) => props.theme.color.greyPalette.white};
  position: fixed;
  top: 0;
  left: 0;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .navigationWrapper {
    .navListWrapper {
      .navItemWrapper {
        width: auto;
        height: auto;
        padding: 8px 8px;
        margin: 8px 0;
        a {
          text-decoration: none;
          position: relative;
          p {
            text-transform: uppercase;
            transition: ${(props) => props.theme.transition};
          }
          &:before {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 0px;
            height: 2px;
            background-color: ${(props) => props.theme.color.greyPalette.white};
            transition: ${(props) => props.theme.transition};
          }
          &:hover {
            p {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.bluePalette.blue};
            }
            &:before {
              content: "";
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: ${(props) => props.theme.color.bluePalette.blue};
              transition: ${(props) => props.theme.transition};
            }
          }
        }

        p {
          text-transform: uppercase;
          transition: ${(props) => props.theme.transition};
        }
        &:before {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 0px;
          height: 2px;
          background-color: ${(props) => props.theme.color.greyPalette.white};
          transition: ${(props) => props.theme.transition};
        }
        &:hover {
          p {
            transition: ${(props) => props.theme.transition};
            color: ${(props) => props.theme.color.bluePalette.blue};
          }
          &:before {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: ${(props) => props.theme.color.bluePalette.blue};
            transition: ${(props) => props.theme.transition};
          }
        }
        .activeNavItem {
          pointer-events: none;
          position: relative;
          p {
            transition: ${(props) => props.theme.transition};
            color: ${(props) => props.theme.color.bluePalette.blue};
          }
        }
      }

      /* SUBNAV STYLE */
      .subNavContainer {
        height: auto;
        width: auto;
        background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
        .subNavHeader {
          height: auto;
          padding: 4px 8px;
          background-color: ${(props) => props.theme.color.greyPalette.darkGrey};

          p {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.greyPalette.white};
          }
        }
        .subNavList {
          height: auto;
          .subNavItem {
            height: auto;
            padding: 8px;

            position: relative;
            a {
              text-decoration: none;
              p {
                text-transform: uppercase;
              }
              &:before {
                content: "";
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 0px;
                height: 2px;
                background-color: ${(props) => props.theme.color.greyPalette.white};
                transition: ${(props) => props.theme.transition};
              }
              &:hover {
                p {
                  transition: ${(props) => props.theme.transition};
                  color: ${(props) => props.theme.color.bluePalette.blue};
                }
                &:before {
                  content: "";
                  position: absolute;
                  bottom: -4px;
                  left: 0;
                  width: 100%;
                  height: 2px;
                  background-color: ${(props) => props.theme.color.bluePalette.blue};
                  transition: ${(props) => props.theme.transition};
                }
              }
            }
            .activeNavItem {
              pointer-events: none;
              p {
                transition: ${(props) => props.theme.transition};
                color: ${(props) => props.theme.color.bluePalette.blue};
              }
            }
          }
          .inactiveItem {
            position: relative;
            a {
              pointer-events: none;
            }
            &:after {
              content: "Em breve";
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              align-content: center;
              height: 0%;
              width: 100%;
              font-size: 14px;
              text-align: center;
              background-color: ${(props) => props.theme.color.orangePalette.orange};
              color: ${(props) => props.theme.color.greyPalette.white};
              transition: ${(props) => props.theme.transition};
              opacity: 0;
            }
            &:hover {
              &:after {
                content: "Em breve";
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                height: 100%;
                width: 100%;
                font-size: 14px;
                text-align: center;
                background-color: ${(props) => props.theme.color.orangePalette.orange};
                color: ${(props) => props.theme.color.greyPalette.white};
                transition: ${(props) => props.theme.transition};
                opacity: 1;
              }
            }
          }
        }
      }
      /* END SUBNAV STYLE */
      .scrollSpyNav {
        .scrollItem {
          a {
            p {
              &:hover {
                transition: ${(props) => props.theme.transition};
                color: ${(props) => props.theme.color.orangePalette.orange};
              }
            }
          }
          .activeNavItem {
            pointer-events: none;
            p {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.orangePalette.orange};
            }
          }
        }
      }

      .iconItem {
        padding: 0 4px;

        a {
          svg {
            width: auto;
            height: 24px;
          }
          &:hover {
            svg {
              path {
                fill: ${(props) => props.theme.color.bluePalette.blue};
              }
            }
            &:before {
              display: none;
            }
          }
        }
      }

      .iconsWrapper {
        height: auto;
      }
    }
  }
  /* LANGUAGE */
  .languageWrapprer {
    height: auto;
    width: auto;
    margin-top: 16px;
    .langListWrapper {
      .langItem {
        width: auto;
        padding: 0 8px;
        cursor: pointer;
        border-right: 1px solid ${(props) => props.theme.color.greyPalette.darkGrey};
        &:last-child {
          border-right: none;
          padding-right: 0;
        }
        &:hover {
          text-decoration: underline;
          color: ${(props) => props.theme.color.greyPalette.darkGrey};
        }
      }
      .activeLangItem {
        h6 {
          font-weight: bold;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @keyframes slide-bottom {
    0% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

class MenuMobile extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected ? this.props.selected : 1,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) this.props.onMenuBurgerOpenChange(false);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //LANGUAGUE
  onHeaderLanguageChange = () => {
    this.getAllComponentsData();
  };

  changeLanguage = (langId) => {
    window.dispatchEvent(new CustomEvent("languageChange", { detail: langId }));
  };
  //END LANGUAGUE
  onSelectClick = (v) => {
    this.setState({ selected: v });
  };

  render() {
    let langId = this.getLanguage();
    return (
      <StyledMenuMobile className="menuMobile" show={this.props.menuBurgerOpen}>
        <NanoFlex className="navigationWrapper">
          <NanoFlex className="navListWrapper" flexDirection={"column"}>
            <NanoFlex className="navItemWrapper">
              <NavLink to="/features" activeClassName="activeNavItem" onClick={() => this.onSelectClick(1)}>
                <Type.p>
                  <b>{this.translation.global.features}</b>
                </Type.p>
              </NavLink>
            </NanoFlex>

            <NanoFlex className="subNavContainer" flexDirection={"column"}>
              <NanoFlex className="subNavHeader">
                <Type.p>
                  <b>{this.translation.global.solutions}</b>
                </Type.p>
              </NanoFlex>
              <NanoFlex className="subNavList" flexDirection={"column"}>
                {/* TO REMOVE "INACTIVEITEM" CLASS */}
                <NanoFlex className="subNavItem inactiveItem">
                  <NavLink to="/premium" activeClassName="activeNavItem" onClick={() => this.onSelectClick(2)}>
                    <Type.p>
                      <b>MLog.Premium</b>
                    </Type.p>
                  </NavLink>
                </NanoFlex>
                <NanoFlex className="subNavItem">
                  <NavLink to="/rh" activeClassName="activeNavItem" onClick={() => this.onSelectClick(3)}>
                    <Type.p>
                      <b>MLog.RH</b>
                    </Type.p>
                  </NavLink>
                </NanoFlex>
                {/* TO REMOVE "INACTIVEITEM" CLASS */}
                <NanoFlex className="subNavItem inactiveItem">
                  <NavLink to="/gp" activeClassName="activeNavItem" onClick={() => this.onSelectClick(4)}>
                    <Type.p>
                      <b>MLog.GP</b>
                    </Type.p>
                  </NavLink>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* SERVICES - descomentar quando houver página*/}
            {/* <NanoFlex className="navItemWrapper">
              <NavLink to="/services" activeClassName="activeNavItem" onClick={() => this.onSelectClick(5)}>
                <Type.p>
                  <b>{this.translation.global.services}</b>
                </Type.p>
              </NavLink>
            </NanoFlex> */}
            {/* END SERVICES - descomentar quando houver página*/}

            {/* PRICING */}
            <NanoFlex className="navItemWrapper">
              <NavLink to="/pricing" activeClassName="activeNavItem" onClick={() => this.onSelectClick(5)}>
                <Type.p>
                  <b>{this.translation.global.pricing}</b>
                </Type.p>
              </NavLink>
            </NanoFlex>
            {/* END PRICING */}

            <NanoFlex className="navItemWrapper">
              <Link to="#contacts" onClick={() => this.onSelectClick(6)}>
                <Type.p>
                  <b>{this.translation.global.contacts}</b>
                </Type.p>
              </Link>
            </NanoFlex>

            <NanoFlex className="navItemWrapper">
              <a href="http://www.medialog.pt/" rel="noopener noreferrer" target="_blank" onClick={() => this.onSelectClick(7)}>
                <Type.p>
                  <b>{this.translation.global.medialog}</b>
                </Type.p>
              </a>
            </NanoFlex>

            <NanoFlex className="iconsWrapper">
              {/* TWITTER - descomentar quando houver */}
              {/* <NanoFlex className="navItemWrapper iconItem" title="Twitter">
                <a href="#" rel="noopener noreferrer" target="_blank" onClick={() => this.onSelectClick(8)}>
                  <Twitter />
                </a>
              </NanoFlex> */}
              {/* END TWITTER - descomentar quando houver */}
              <NanoFlex className="navItemWrapper iconItem" title="LinkedIn">
                <a href="https://www.linkedin.com/company/mlogsoftware" rel="noopener noreferrer" target="_blank" onClick={() => this.onSelectClick(9)}>
                  <Linkedin />
                </a>
              </NanoFlex>
            </NanoFlex>

            {/* LANGUAGES - descomentar quando houver traduções */}
            {/* <NanoFlex className="languageWrapprer" justifyContent={"flex-end"} alignItems={"flex-end"}>
              <NanoFlex className="langListWrapper" justifyContent={"flex-end"}>
                <NanoFlex className={`langItem ${langId === null ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(null)}>
                  <Type.h6>PT</Type.h6>
                </NanoFlex>
                <NanoFlex className={`langItem ${langId === 1 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(1)}>
                  <Type.h6>ENG</Type.h6>
                </NanoFlex>
                <NanoFlex className={`langItem ${langId === 2 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(2)}>
                  <Type.h6>ES</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex> */}
            {/* END LANGUAGES - descomentar quando houver traduções */}
          </NanoFlex>
        </NanoFlex>
      </StyledMenuMobile>
    );
  }
}

//Set default props
MenuMobile.defaultProps = {
  show: false,
};

export default MenuMobile;

// --- // Documentation // --- //
/*
import MenuMobile from './NavBar/MenuMobile.js';

<MenuMobile />
*/
