import React from "react";
import styled from "styled-components";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

const StyledArticle = styled(NanoFlex)`
  width: auto;
  height: auto;
  justify-content: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
  align-items: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
  .titleWrapper {
    justify-content: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
    align-items: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
    margin-bottom: 8px;
    text-align: ${(props) => (props.left ? "left" : props.right ? "right" : "center")};

    .title {
      color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : "")};
      text-transform: uppercase;
      b {
        color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : "")};
      }
    }
  }

  .subtitlteWrapper {
    justify-content: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
    align-items: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
    text-align: ${(props) => (props.left ? "left" : props.right ? "right" : "center")};

    .subtitle {
      text-transform: uppercase;
    }
  }
`;

const Title = (props) => {
  return (
    <StyledArticle flexDirection="column" left={props.left} right={props.right} blue={props.blue} yellow={props.yellow} orange={props.orange}>
      <NanoFlex className="titleWrapper">
        <Type.h2 className="title">
          <b>{props.title}</b>
        </Type.h2>
      </NanoFlex>
      <NanoFlex className="subtitlteWrapper">
        <Type.h3 className="subtitle">{props.subtitle}</Type.h3>
      </NanoFlex>
    </StyledArticle>
  );
};

// Set default props
Title.defaultProps = {
  title: "",
  subtitle: "",
  left: false,
  right: false,
  blue: false,
  yellow: false,
  orange: false,
  blue: false,
};

export default Title;

// --- // Documentation // --- //
/*
  import Title from './Titles/Title.js';
  
  <Title title="Lorem ipsum" subtitle="Lorem ipsum"/>
  */
