import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import ScrollspyNav from "react-scrollspy-nav";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import ButtonCTA from "../../Buttons/ButtonCTA.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import { ReactComponent as Illustration } from "../../Global/images/illustrationHeaderHP.svg";
import { ReactComponent as Powered } from "../../Global/images/poweredByMedialog.svg";

const StyledHeader = styled(NanoFlex)`
  height: auto;
  a {
    text-decoration: none;
  }
  .infoAside {
    height: auto;
    padding: 60px;
    background-color: ${(props) => props.theme.color.bluePalette.lightBlue};
    .logoWrapper {
      width: 74%;
      svg {
        height: 100px;
        width: auto;
      }
    }
    .mainInfoWrapper {
      margin: 24px 0;
      width: 74%;
    }
    .logoPowered {
      height: auto;
      svg {
        height: auto;
        width: 100px;
        path {
          fill: ${(props) => props.theme.color.greyPalette.darkGrey};
        }
      }
    }
  }
  .illustrationAside {
    position: relative;
    height: auto;
    .illustrationWrapper {
      svg {
        max-height: 580px;
        width: auto;
      }
    }
    /* .logoPowered {
      height: auto;
      width: auto;
      position: absolute;
      bottom: 30px;
      right: 30px;
      svg {
        height: auto;
        width: 120px;
        path {
          fill: ${(props) => props.theme.color.greyPalette.darkGrey};
        }
      }
    } */
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    .illustrationAside {
      overflow: hidden;
      .illustrationWrapper {
        svg {
          max-height: 450px;
        }
      }
    }
  }

  @media only screen and (max-width: 760px) {
    .infoAside {
      padding: 24px;
      align-items: flex-start;
      .mainInfoWrapper {
        width: 100%;
      }
    }
    .illustrationAside {
      display: none;
    }
  }
`;

class Header extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledHeader alignItems={"flex-start"} alignItems={"stretch"}>
        <NanoFlex className="infoAside" flexDirection={"column"} alignContent={"stretch"}>
          <NanoFlex className="logoWrapper" justifyContent={"flex-start"} alignItems={"flex-start"}>
            <MlogLogo />
          </NanoFlex>
          <NanoFlex className="mainInfoWrapper" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
            <Type.h1>
              <b>{this.translation.homepage.header.slogan}</b>
            </Type.h1>
            <Type.h3>{this.translation.homepage.header.signature}</Type.h3>
          </NanoFlex>
          <NanoFlex className="ctaWrapper">
            {/* <ScrollspyNav scrollTargetIds={["packs"]} activeNavClass="activeNavItem" scrollDuration="100" offset={-124}>
              <ul>
                <li className="navItemWrapper">
                  <a href="#packs">
                    <ButtonCTA label={this.translation.global.knowMore} />
                  </a>
                </li>
              </ul>
            </ScrollspyNav> */}
            <Link to="#packs">
              <ButtonCTA label={this.translation.global.knowMore} />
            </Link>
          </NanoFlex>
          <NanoFlex className="logoPowered" justifyContent={"flex-end"}>
            <a href="http://www.medialog.pt/" rel="noopener noreferrer" target="_blank">
              <Powered />
            </a>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="illustrationAside" flexDirection={"column"} alignItems={"stretch"} alignContent={"stretch"}>
          <NanoFlex className="illustrationWrapper">
            <Illustration />
          </NanoFlex>
          {/* <NanoFlex className="logoPowered">
            <a href="http://www.medialog.pt/" rel="noopener noreferrer" target="_blank">
              <Powered />
            </a>
          </NanoFlex> */}
        </NanoFlex>
      </StyledHeader>
    );
  }
}

export default Header;

// --- // Documentation // --- //
/*
  import Header from './Header.js';
  
  <Header />
  */
