import React from "react";
import BaseComponent from "../../BaseComponent.js";
import { NavLink, Link } from "react-router-dom";
import ScrollspyNav from "react-scrollspy-nav";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//COMPONENTS
import MenuBurguer from "../../Animations/MenuBurguer.js";
// import MenuMobile from "./MenuMobile.js";
// import Modal from "../Modal/Modal.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogo.svg";
import { ReactComponent as Twitter } from "../../Global/icons/twitter.svg";
import { ReactComponent as Linkedin } from "../../Global/icons/linkedin.svg";

const StyledNavbar = styled(NanoFlex)`
  height: auto;
  user-select: none;

  /* NAVBAR */
  .nav {
    height: auto;
    background-color: ${(props) => props.theme.color.greyPalette.white};
    padding: 16px 32px;
    transition: ${(props) => props.theme.transition};
    position: relative;
    z-index: 9;
    .logoWrapper {
      svg {
        height: 36px;
        width: auto;
        transition: ${(props) => props.theme.transition};
      }
    }
    .navigationWrapper {
      .navListWrapper {
        .navItemWrapper {
          width: auto;
          padding: 0 8px;

          a {
            text-decoration: none;
            position: relative;
            p {
              text-transform: uppercase;
              transition: ${(props) => props.theme.transition};
            }
            &:before {
              content: "";
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 0px;
              height: 2px;
              background-color: ${(props) => props.theme.color.greyPalette.white};
              transition: ${(props) => props.theme.transition};
            }
            &:hover {
              p {
                transition: ${(props) => props.theme.transition};
                color: ${(props) => props.theme.color.bluePalette.blue};
              }
              &:before {
                content: "";
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: ${(props) => props.theme.color.bluePalette.blue};
                transition: ${(props) => props.theme.transition};
              }
            }
          }

          .activeNavItem {
            pointer-events: none;
            position: relative;
            p {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.bluePalette.blue};
            }
          }
        }
        /* DROPDOWN */
        .dropDownContainer {
          position: relative;
          cursor: pointer;
          transition: ${(props) => props.theme.transition};

          p {
            text-transform: uppercase;
            transition: ${(props) => props.theme.transition};
          }
          &:before {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 0px;
            height: 2px;
            background-color: ${(props) => props.theme.color.greyPalette.white};
            transition: ${(props) => props.theme.transition};
          }

          .dropDownWrapper {
            width: auto;
            height: 108px;
            padding: 8px;
            position: absolute;
            top: 24px;
            left: 0;
            border-radius: 0 0 16px 16px;
            background-color: ${(props) => props.theme.color.greyPalette.white};
            -webkit-box-shadow: 0px 1px 0px 0px rgba(71, 71, 71, 0.25);
            -moz-box-shadow: 0px 1px 0px 0px rgba(71, 71, 71, 0.25);
            box-shadow: 0px 1px 0px 0px rgba(71, 71, 71, 0.25);
            .dropDownItem {
              margin: 4px;
              height: auto;

              a {
                width: 100%;
                border-left: 2px solid ${(props) => props.theme.color.greyPalette.grey};
                padding-left: 4px;
                p {
                  font-size: 14px;
                }
                &:before {
                  display: none;
                }
              }
              .activeNavItem {
                border-left: 2px solid ${(props) => props.theme.color.bluePalette.blue};
              }
              &:hover {
                a {
                  border-left: 2px solid ${(props) => props.theme.color.bluePalette.blue};
                }

                p {
                  transition: ${(props) => props.theme.transition};
                  color: ${(props) => props.theme.color.bluePalette.blue};
                }
              }
            }

            .comingSoonWrapper {
              margin-top: 4px;
              background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
              .comingSoon {
                background-color: ${(props) => props.theme.color.greyPalette.darkGrey};
                margin-bottom: 4px;
                padding: 0px 4px;
                height: auto;
                h6 {
                  font-size: 12px;
                  text-transform: uppercase;
                  color: ${(props) => props.theme.color.greyPalette.white};
                }
              }
            }
            .inactiveItem {
              position: relative;
              margin: 0px;
              padding: 0 4px;
              pointer-events: none;
              a {
                pointer-events: none;
                border-left: none;
                p {
                  font-size: 14px;
                }
              }
              &:hover {
                a {
                  border-left: none;
                }
              }
            }
          }
          .itemToDropDown {
            &:hover {
              p {
                transition: ${(props) => props.theme.transition};
                color: ${(props) => props.theme.color.orangePalette.orange};
              }
            }
          }
        }
        .openedDropDown {
          .itemToDropDown {
            p {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.orangePalette.orange};
            }
          }
        }
        /* END DROPDOWN */

        /* SUBNAV STYLE */
        .subNavContainer {
          height: auto;
          min-width: 124px;
          max-width: 124px;
          width: 124px;
          background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
          position: relative;
          transition: ${(props) => props.theme.transition};
          .subNavHeader {
            cursor: pointer;
            height: auto;
            padding: 4px 8px;
            background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
            p {
              text-transform: uppercase;
              color: ${(props) => props.theme.color.greyPalette.darkGrey};
            }
          }
          .subNavList {
            height: 0;
            overflow: hidden;
            transition: ${(props) => props.theme.transition};
            position: absolute;
            top: 24px;
            background-color: ${(props) => props.theme.color.greyPalette.white};
            border: 1px solid ${(props) => props.theme.color.greyPalette.white};
            .subNavItem {
              height: auto;
              padding: 8px;
              position: relative;
              transition: ${(props) => props.theme.transition};
              a {
                text-decoration: none;
                p {
                  text-transform: uppercase;
                }
                &:before {
                  content: "";
                  position: absolute;
                  bottom: 0px;
                  left: 0;
                  width: 0px;
                  height: 2px;
                  background-color: ${(props) => props.theme.color.greyPalette.white};
                  transition: ${(props) => props.theme.transition};
                }
                &:hover {
                  p {
                    transition: ${(props) => props.theme.transition};
                    color: ${(props) => props.theme.color.bluePalette.blue};
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: ${(props) => props.theme.color.bluePalette.blue};
                    transition: ${(props) => props.theme.transition};
                  }
                }
              }
              .activeNavItem {
                pointer-events: none;
                p {
                  transition: ${(props) => props.theme.transition};
                  color: ${(props) => props.theme.color.bluePalette.blue};
                }
              }
            }
            .inactiveItem {
              position: relative;
              a {
                pointer-events: none;
              }
              &:after {
                content: "Em breve";
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                height: 0%;
                width: 100%;
                font-size: 14px;
                text-align: center;
                background-color: ${(props) => props.theme.color.orangePalette.orange};
                color: ${(props) => props.theme.color.greyPalette.white};
                transition: ${(props) => props.theme.transition};
                opacity: 0;
              }
              &:hover {
                &:after {
                  content: "Em breve";
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  align-content: center;
                  height: 100%;
                  width: 100%;
                  font-size: 14px;
                  text-align: center;
                  background-color: ${(props) => props.theme.color.orangePalette.orange};
                  color: ${(props) => props.theme.color.greyPalette.white};
                  transition: ${(props) => props.theme.transition};
                  opacity: 1;
                }
              }
            }
          }
          &:hover {
            transition: ${(props) => props.theme.transition};
            .subNavList {
              transition: ${(props) => props.theme.transition};
              border: 1px solid ${(props) => props.theme.color.greyPalette.lighterGrey};
              height: auto;
            }
          }
        }
        /* END SUBNAV STYLE */

        .scrollSpyNav {
          .scrollItem {
            a {
              p {
                &:hover {
                  transition: ${(props) => props.theme.transition};
                  color: ${(props) => props.theme.color.orangePalette.orange};
                }
              }
            }
            .activeNavItem {
              pointer-events: none;
              p {
                transition: ${(props) => props.theme.transition};
                color: ${(props) => props.theme.color.orangePalette.orange};
              }
            }
          }
        }

        .iconItem {
          padding: 0 4px;
          margin-left: 16px;
          a {
            svg {
              width: auto;
              height: 24px;
            }
            &:hover {
              svg {
                path {
                  fill: ${(props) => props.theme.color.bluePalette.blue};
                }
              }
              &:before {
                display: none;
              }
            }
          }

          &:last-child {
            margin-left: 0px;
            padding-right: 0;
          }
        }
      }
    }
    .navMobile {
      display: none;
    }
  }
  /* FIXED NAVBAR */
  .fixedNav {
    z-index: 9;
    position: fixed;
    top: 0;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(71, 71, 71, 0.44);
    -moz-box-shadow: 0px 5px 5px 0px rgba(71, 71, 71, 0.44);
    box-shadow: 0px 5px 5px 0px rgba(71, 71, 71, 0.44);
    transition: ${(props) => props.theme.transition};
    padding: 8px 32px;
    .logoWrapper {
      svg {
        height: 30px;
        width: auto;
        transition: ${(props) => props.theme.transition};
      }
    }
  }

  /* LANGUAGE */
  .languageWrapprer {
    width: auto;
    margin-left: 16px;
    .langListWrapper {
      .langItem {
        width: auto;
        padding: 0 8px;
        cursor: pointer;
        border-right: 1px solid ${(props) => props.theme.color.greyPalette.darkGrey};
        &:last-child {
          border-right: none;
          padding-right: 0;
        }
        &:hover {
          text-decoration: underline;
          color: ${(props) => props.theme.color.greyPalette.darkGrey};
        }
      }
      .activeLangItem {
        h6 {
          font-weight: bold;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    .nav {
      .navigationWrapper {
        display: none;
      }
      .navMobile {
        display: flex;
      }
    }
  }
`;

class Navbar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fixedNav: null,
    };
  }

  // FIXED NAVBAR
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("scroll", () => {
      let fixedNav = "normal";
      if (window.scrollY === 0) {
        fixedNav = null;
      } else {
        fixedNav = "top";
      }
      this.setState({ fixedNav });
      this.state = {
        expand: false,
      };
    });
  }
  // END FIXED NAVBAR

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  scrollToTop = (v) => {
    window.scrollTo(0, 0);
    this.setState({ selected: v });
  };

  //LANGUAGUE
  onHeaderLanguageChange = () => {
    this.getAllComponentsData();
  };

  changeLanguage = (langId) => {
    window.dispatchEvent(new CustomEvent("languageChange", { detail: langId }));
  };
  //END LANGUAGUE
  onMenuBurgerOpenChange = (val) => {
    if (this.props.onMenuBurgerOpenChange) this.props.onMenuBurgerOpenChange(val);
  };

  toggleExpand = () => {
    this.setState({ expand: !this.state.expand });
  };

  render() {
    let langId = this.getLanguage();

    return (
      <StyledNavbar flexDirection={"column"}>
        <NanoFlex className={`nav ${this.state.fixedNav ? "fixedNav" : ""}`}>
          <NanoFlex className="logoWrapper" justifyContent={"flex-start"}>
            <NavLink
              to="/"
              onClick={() => {
                this.scrollToTop(1);
              }}>
              <MlogLogo />
            </NavLink>
          </NanoFlex>
          <NanoFlex className="navigationWrapper">
            <NanoFlex className="navListWrapper" justifyContent={"flex-end"}>
              <NanoFlex className="navItemWrapper">
                <NavLink to="/features" activeClassName="activeNavItem">
                  <Type.p>
                    <b>{this.translation.global.features}</b>
                  </Type.p>
                </NavLink>
              </NanoFlex>

              <NanoFlex className="subNavContainer" flexDirection={"column"}>
                <NanoFlex className="subNavHeader">
                  <Type.p>
                    <b>{this.translation.global.solutions}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="subNavList" flexDirection={"column"}>
                  {/* TO REMOVE "INACTIVEITEM" CLASS */}
                  <NanoFlex className="subNavItem inactiveItem">
                    <NavLink to="/premium" activeClassName="activeNavItem">
                      <Type.p>
                        <b>MLog.Premium</b>
                      </Type.p>
                    </NavLink>
                  </NanoFlex>
                  <NanoFlex className="subNavItem">
                    <NavLink to="/rh" activeClassName="activeNavItem">
                      <Type.p>
                        <b>MLog.RH</b>
                      </Type.p>
                    </NavLink>
                  </NanoFlex>
                  {/* TO REMOVE "INACTIVEITEM" CLASS */}
                  <NanoFlex className="subNavItem inactiveItem">
                    <NavLink to="/gp" activeClassName="activeNavItem">
                      <Type.p>
                        <b>MLog.GP</b>
                      </Type.p>
                    </NavLink>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* SERVICES - descomentar quando houver página*/}
              {/* <NanoFlex className="navItemWrapper">
                <NavLink to="/services" activeClassName="activeNavItem">
                  <Type.p>
                    <b>{this.translation.global.services}</b>
                  </Type.p>
                </NavLink>
              </NanoFlex> */}
              {/* END SERVICES - descomentar quando houver página*/}

              {/* PRICING */}
              <NanoFlex className="navItemWrapper">
                <NavLink to="/pricing" activeClassName="activeNavItem">
                  <Type.p>
                    <b>{this.translation.global.pricing}</b>
                  </Type.p>
                </NavLink>
              </NanoFlex>
              {/* END PRICING */}

              <NanoFlex className="navItemWrapper">
                <Link to="#contacts" activeClassName="activeNavItem">
                  <Type.p>
                    <b>{this.translation.global.contacts}</b>
                  </Type.p>
                </Link>
              </NanoFlex>

              <NanoFlex className="navItemWrapper">
                <a href="http://www.medialog.pt/" rel="noopener noreferrer" target="_blank">
                  <Type.p>
                    <b>{this.translation.global.medialog}</b>
                  </Type.p>
                </a>
              </NanoFlex>
              {/* TWITTER - descomentar quando houver */}
              {/* <NanoFlex className="navItemWrapper iconItem" title="Twitter">
                <a href="#" rel="noopener noreferrer" target="_blank">
                  <Twitter />
                </a>
              </NanoFlex> */}
              {/* END TWITTER - descomentar quando houver */}
              <NanoFlex className="navItemWrapper iconItem" title="LinkedIn">
                <a href="https://www.linkedin.com/company/mlogsoftware" rel="noopener noreferrer" target="_blank">
                  <Linkedin />
                </a>
              </NanoFlex>
            </NanoFlex>

            {/* LANGUAGES - descomentar quando houver traduções */}
            {/* <NanoFlex className="languageWrapprer" justifyContent={"flex-end"} alignItems={"flex-end"}>
              <NanoFlex className="langListWrapper" justifyContent={"flex-end"}>
                <NanoFlex className={`langItem ${langId === null ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(null)}>
                  <Type.h6>PT</Type.h6>
                </NanoFlex>
                <NanoFlex className={`langItem ${langId === 1 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(1)}>
                  <Type.h6>ENG</Type.h6>
                </NanoFlex>
                <NanoFlex className={`langItem ${langId === 2 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(2)}>
                  <Type.h6>ES</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex> */}
            {/* END LANGUAGES - descomentar quando houver traduções */}
          </NanoFlex>
          <NanoFlex className="navMobile" justifyContent="flex-end">
            <MenuBurguer open={this.props.menuBurgerOpen} onOpenChange={this.onMenuBurgerOpenChange} />
          </NanoFlex>
        </NanoFlex>
      </StyledNavbar>
    );
  }
}

export default Navbar;

// --- // Documentation // --- //
/*
import Navbar from './Shared/Navbar.js';

<Navbar />
*/
