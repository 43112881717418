import React from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";

const CTAButtonStyled = styled(NanoFlex)`
  height: auto;
  width: auto;
  margin: 8px;
  .ctaWrapper {
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    text-transform: uppercase;
    padding: 6px 24px;
    border-radius: 50px;
    border: none;
    border: 0;
    background-color: ${(props) => (props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.bluePalette.blue)};
    border: 2px solid ${(props) => (props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.bluePalette.blue)};
    transition: ${(props) => props.theme.transition};
    text-transform: uppercase;
    color: ${(props) => props.theme.color.greyPalette.white};
    font-size: 16px;
    font-weight: bold;

    &:hover {
      transition: ${(props) => props.theme.transition};
      border: 2px solid ${(props) => props.theme.color.greyPalette.lightGrey};

      -webkit-box-shadow: inset 0px 0px 16px -11px rgba(0, 0, 0, 0.7);
      -moz-box-shadow: inset 0px 0px 16px -11px rgba(0, 0, 0, 0.7);
      box-shadow: inset 0px 0px 16px -11px rgba(0, 0, 0, 0.7);
    }
    &:focus {
      outline: 0;
    }
  }
  .disabled {
    background-color: ${(props) => props.theme.color.greyPalette.lightGrey};
    border: 2px solid ${(props) => props.theme.color.greyPalette.lightGrey};
    pointer-events: none;
  }
`;

const CTAButton = (props) => {
  return (
    <CTAButtonStyled className="cta" yellow={props.yellow} orange={props.orange}>
      <button className={`ctaWrapper ${props.disabled ? "disabled" : ""}`} type={props.type} onClick={() => props.clickAction(props.id)}>
        {props.label}
      </button>
    </CTAButtonStyled>
  );
};

// Set default props
CTAButton.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  yellow: false,
  orange: false,
  clickAction: () => null,
};

export default CTAButton;

/*
  Doc
  import Cta from './Buttons/CTA/CTAButton.js';
  
  <CTA label="Contacte-nos"/>
  <CTA pink label="Contacte-nos"/>
  <CTA blue label="Contacte-nos"/>
  */
