import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import Title from "../../Blocks/Titles/Title.js";

const StyledBenefitsBlock = styled(NanoFlex)`
  height: auto;
  .benefitsBlockWrapper {
    padding: 40px 60px;
    background-color: ${(props) => props.theme.color.yellowPalette.lightYellow};
    .headerBenefits {
      height: auto;
    }
    /* CAROUSEL */
    .carousel-root {
      width: 100%;

      .carousel-slider {
        width: 100%;
        height: auto;
        .control-arrow {
          display: none;
        }
        .slider-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          .slider {
            width: 100%;
            padding: 42px 0;
            .slide {
              width: 100%;
              background-color: unset;
              .carouselItem {
                width: 100%;
              }
            }
          }
        }
        .control-dots {
          position: unset;
          .dot {
            width: 20px;
            height: 8px;
            border-radius: 20px;
            margin: 0 4px;
            box-shadow: unset;
            background-color: ${(props) => props.theme.color.greyPalette.grey};
            &:hover {
              background-color: ${(props) => props.theme.color.greyPalette.darkGrey};
            }
          }
          .selected {
            pointer-events: none;
            background-color: ${(props) => props.theme.color.bluePalette.blue};
          }
        }
        .carousel-status {
          display: none;
        }
      }
      .carousel {
        .axis-vertical {
          display: none;
        }
      }
    }
    /* END CAROUSEL */
  }

  @media only screen and (max-width: 760px) {
    .benefitsBlockWrapper {
      padding: 20px;
    }
  }
`;

class BenefitsBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledBenefitsBlock>
        <NanoFlex className="benefitsBlockWrapper" flexDirection={"column"}>
          <NanoFlex className="headerBenefits">
            <Title yellow title={this.translation.homepage.benefits.title} subtitle={this.translation.homepage.benefits.subtitle} />
          </NanoFlex>
          <Carousel autoPlay>
            <NanoFlex className="carouselItem">
              <Type.h4>
                <b>{this.translation.homepage.benefits.benefit01}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="carouselItem">
              <Type.h4>
                <b>{this.translation.homepage.benefits.benefit02}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="carouselItem">
              <Type.h4>
                <b>{this.translation.homepage.benefits.benefit03}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="carouselItem">
              <Type.h4>
                <b>{this.translation.homepage.benefits.benefit04}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="carouselItem">
              <Type.h4>
                <b>{this.translation.homepage.benefits.benefit05}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="carouselItem">
              <Type.h4>
                <b>{this.translation.homepage.benefits.benefit06}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="carouselItem">
              <Type.h4>
                <b>{this.translation.homepage.benefits.benefit07}</b>
              </Type.h4>
            </NanoFlex>
          </Carousel>
        </NanoFlex>
      </StyledBenefitsBlock>
    );
  }
}

export default BenefitsBlock;

// --- // Documentation // --- //
/*
  import BenefitsBlock from './BenefitsBlock.js';
  
  <BenefitsBlock />
  */
