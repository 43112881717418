import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";

//COMPONENTS
import Header from "./Header.js";
import PacksBlock from "./PacksBlock.js";

const StyledPricing = styled(NanoFlex)``;

export class Pricing extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledPricing flexDirection={"column"}>
        <Header />
        <PacksBlock />
      </StyledPricing>
    );
  }
}
