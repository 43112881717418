import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

// IMAGES
import { ReactComponent as MlogIcon } from "../../Global/icons/mlogIcon.svg";

const StyledCardPrice = styled(NanoFlex)`
  width: auto;
  height: auto;
  margin: 8px;
  .cardWrapper {
    width: 260px;
    background-color: ${(props) => props.theme.color.greyPalette.white};
    border-radius: 40px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: ${(props) => (props.comingSoon ? "default" : "pointer")};
    transition: ${(props) => props.theme.transition};
    .headerCard {
      height: auto;
      padding: 16px;
      a {
        text-decoration: none;
        width: 100%;
        cursor: ${(props) => (props.comingSoon ? "default" : "pointer")};
        pointer-events: ${(props) => (props.comingSoon ? "none" : "initial")};
      }
      .iconHeader {
        height: auto;
        svg {
          width: 40px;
          height: auto;
          .colorPoint {
            fill: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          }
        }
      }
      .titleHeader {
        height: auto;
        margin: 16px 16px 0 0px;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
        padding-bottom: 16px;
        h3 {
          text-transform: uppercase;
          em {
            color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          }
        }
      }
    }
    .pricingCardWrapper {
      .pricesContainer {
        margin-bottom: 8px;
        padding: 0 16px;
        .priceWrapper {
          .oldPrice {
            h4 {
              text-decoration: line-through;
            }
          }
          .newTopPrice {
            h2 {
              strong {
                color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
              }
            }
            h6 {
              text-transform: uppercase;
            }
          }
          .priceSubtitle {
            margin-top: 8px;
          }
          .ctaPriceContainer {
            margin-top: 16px;
            a {
              text-decoration: none;
            }
            .ctaPrice {
              width: auto;
              user-select: none;
              cursor: pointer;
              padding: 6px 24px;
              border-radius: 50px;
              background-color: ${(props) => props.theme.color.greyPalette.white};
              border: 2px solid ${(props) => (props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.bluePalette.blue)};
              transition: ${(props) => props.theme.transition};

              h5 {
                font-size: 16px;
                line-height: initial;
                color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
                text-transform: uppercase;
              }

              &:hover {
                transition: ${(props) => props.theme.transition};
                -webkit-box-shadow: 0px 0px 16px -11px rgba(0, 0, 0, 0.7);
                -moz-box-shadow: 0px 0px 16px -11px rgba(0, 0, 0, 0.7);
                box-shadow: 0px 0px 16px -11px rgba(0, 0, 0, 0.7);
              }
            }
          }
        }
        .topPriceWrapper {
          background-color: ${(props) => (props.blue ? props.theme.color.bluePalette.lightBlue : props.yellow ? props.theme.color.yellowPalette.lightYellow : props.orange ? props.theme.color.orangePalette.lightOrange : props.theme.color.greyPalette.lightGrey)};
          padding: 16px;
          margin-bottom: 8px;
          border-radius: 40px 40px 0 0;
        }
        .bottomPriceWrapper {
          background-color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          padding: 16px;
          .oldPrice {
            h4 {
              color: ${(props) => props.theme.color.greyPalette.white};
            }
          }
          .newTopPrice {
            h2 {
              strong {
                color: ${(props) => props.theme.color.greyPalette.white};
              }
            }
            h6 {
              color: ${(props) => props.theme.color.greyPalette.white};
            }
          }
          .priceSubtitle {
            p {
              color: ${(props) => props.theme.color.greyPalette.white};
            }
          }
          .ctaPrice {
            border: 2px solid ${(props) => props.theme.color.greyPalette.white};
          }
        }
      }

      .featuresContainer {
        padding: 0 16px 16px 16px;
        .featuresWrapper {
          background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
          border-radius: 0 0 40px 40px;
          padding: 16px;
          .featuresTitle {
            margin-bottom: 8px;
            height: auto;
          }
          .featuresList {
            height: auto;
            .featureItem {
              padding: 6px;
              .iconFeature {
                width: 32px;
                img {
                  height: 16px;
                  width: 100%;
                  max-width: 20px;
                }
              }
            }
            .titleFeature {
              width: 104px;
            }
          }
          .featuresObservation {
            text-align: center;
            height: auto;
          }
        }
      }
    }
    .comingSoonContainer {
      padding: 0 16px 16px 16px;
      .comingSoonWrapper {
        background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
        border-radius: 40px;
        padding: 16px;
      }
    }
    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35);
      transition: ${(props) => props.theme.transition};
    }
  }
`;

class CardPrice extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledCardPrice flexDirection="column" blue={this.props.blue} yellow={this.props.yellow} orange={this.props.orange} comingSoon={this.props.comingSoon}>
        <NanoFlex className="cardWrapper" flexDirection="column">
          <NanoFlex className="headerCard" flexDirection="column">
            <Link to={this.props.packLink}>
              <NanoFlex className="iconHeader">
                <MlogIcon />
              </NanoFlex>
              <NanoFlex className="titleHeader">
                <Type.h3>
                  <b>
                    Mlog
                    <em>.{this.props.data.pack}</em>
                  </b>
                </Type.h3>
              </NanoFlex>
            </Link>
          </NanoFlex>
          {!this.props.comingSoon && (
            <NanoFlex className="pricingCardWrapper" flexDirection="column">
              <NanoFlex className="pricesContainer" flexDirection="column">
                <NanoFlex className="priceWrapper topPriceWrapper" flexDirection="column">
                  <NanoFlex className="oldPrice">
                    <Type.h4>{this.props.data.oldTopPrice}</Type.h4>
                  </NanoFlex>
                  <NanoFlex className="newTopPrice" flexDirection="column">
                    <Type.h2>
                      <strong>{this.props.data.newTopPrice}</strong>
                    </Type.h2>
                    <Type.h6>Utilizador / mês</Type.h6>
                  </NanoFlex>
                  <NanoFlex className="priceSubtitle">
                    <Type.p>{this.props.data.subtitleTopPrice}</Type.p>
                  </NanoFlex>
                  <NanoFlex className="ctaPriceContainer">
                    <Link to="#contacts">
                      <NanoFlex className="ctaPrice">
                        <Type.h5>
                          <strong>Pedir Demo</strong>
                        </Type.h5>
                      </NanoFlex>
                    </Link>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="priceWrapper bottomPriceWrapper" flexDirection="column">
                  <NanoFlex className="oldPrice">
                    <Type.h4>{this.props.data.oldBottomPrice}</Type.h4>
                  </NanoFlex>
                  <NanoFlex className="newTopPrice" flexDirection="column">
                    <Type.h2>
                      <strong>{this.props.data.newBottomPrice}</strong>
                    </Type.h2>
                    <Type.h6>Utilizador / mês</Type.h6>
                  </NanoFlex>
                  <NanoFlex className="priceSubtitle">
                    <Type.p>{this.props.data.subtitleBottomPrice}</Type.p>
                  </NanoFlex>
                  <NanoFlex className="ctaPriceContainer">
                    <Link to="#contacts">
                      <NanoFlex className="ctaPrice">
                        <Type.h5>
                          <strong>Pedir Demo</strong>
                        </Type.h5>
                      </NanoFlex>
                    </Link>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="featuresContainer" flexDirection="column">
                <NanoFlex className="featuresWrapper" flexDirection="column" justifyContent="space-between">
                  <NanoFlex className="featuresTitle">
                    <Type.p>
                      <strong>Features incluídas</strong>
                    </Type.p>
                  </NanoFlex>
                  <NanoFlex className="featuresList" flexDirection="column">
                    {this.props.data.features?.map((item) => {
                      return (
                        <NanoFlex className="featureItem">
                          <NanoFlex className="iconFeature">
                            <img src={item.icon} alt="icon" />
                          </NanoFlex>
                          <NanoFlex className="titleFeature" justifyContent={"flex-start"}>
                            <Type.p>{item.name}</Type.p>
                          </NanoFlex>
                        </NanoFlex>
                      );
                    })}
                  </NanoFlex>
                  <NanoFlex className="featuresObservation">
                    <Type.h6>{this.props.data.featuresObservation}</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          )}
          {this.props.comingSoon && (
            <NanoFlex className="comingSoonContainer">
              <NanoFlex className="comingSoonWrapper">
                <Type.h4>
                  <b>{this.translation.global.comingSoon}</b>
                </Type.h4>
              </NanoFlex>
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledCardPrice>
    );
  }
}

export default CardPrice;

// --- // Documentation // --- //
/*
  import Card from './Cards/CardPrice.js';
  
  <CardPrice yellow/>
  */
