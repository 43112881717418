import React from "react";
import BaseComponent from "../../BaseComponent.js";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import Title from "../../Blocks/Titles/Title.js";
import SmallCardFeat from "../../Blocks/Cards/SmallCardFeat.js";
import ButtonCTA from "../../Buttons/ButtonCTA.js";

// IMAGES
import Candidates from "../../Global/icons/candidates.svg";
import Clients from "../../Global/icons/clients.svg";
import Consultants from "../../Global/icons/consultants.svg";
import Expenses from "../../Global/icons/expenses.svg";
import Projects from "../../Global/icons/projects.svg";
import RecruitmentRequests from "../../Global/icons/recruitmentRequests.svg";
import Reports from "../../Global/icons/reports.svg";
import Schedule from "../../Global/icons/schedule.svg";
import Timesheets from "../../Global/icons/timesheets.svg";
import Features from "../../Global/icons/features.svg";

const StyledFeaturesBlock = styled(NanoFlex)`
  height: auto;
  a {
    text-decoration: none;
  }
  .featsBlockWrapper {
    padding: 40px 60px;
    position: relative;
    background-color: ${(props) => props.theme.color.bluePalette.lighterBlue};
    .featsContainer {
      /* width: auto; */
      max-width: 70vw;
      .headerWrapper {
        margin-bottom: 24px;
      }
      .featsList {
        width: auto;
        .cardContainer {
          width: auto;
          margin: 16px;
          position: relative;
        }
      }
    }
    .featsMobile {
      padding: 40px 32px;
      .infoWrapper {
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }

  @media only screen and (max-width: 760px) {
    .featsBlockWrapper {
      padding: 20px;
      .featsMobile {
        padding: 0;
        max-width: 100%;
      }
    }
  }
`;

class FeaturesBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledFeaturesBlock>
        <NanoFlex className="featsBlockWrapper">
          {!this._isMobile && (
            <NanoFlex className="featsContainer" flexDirection={"column"}>
              <NanoFlex className="headerWrapper" justifyContent={"flex-end"}>
                <Title right orange title={this.translation.homepage.features.title} subtitle={this.translation.homepage.features.subtitle} />
              </NanoFlex>

              <NanoFlex className="featsList" flexWrap={"wrap"}>
                <NanoFlex className="cardContainer">
                  <Link to="/features#reports">
                    <SmallCardFeat blue icon={Reports} feat="reports" />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#candidates">
                    <SmallCardFeat blue icon={Candidates} feat="Candidatos" />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#consultants">
                    <SmallCardFeat blue icon={Consultants} feat="Colaboradores" />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#clients">
                    <SmallCardFeat blue icon={Clients} feat="Clientes" />
                  </Link>
                </NanoFlex>
                {/* PROJECTS - descomentar quando houver GP - Product Page */}
                {/* <NanoFlex className="cardContainer">
                  <Link to="/features#projects">
                    <SmallCardFeat blue icon={Projects} feat="Projectos" />
                  </Link>
                </NanoFlex> */}
                {/* END PROJECTS - descomentar quando houver GP - Product Page */}
                <NanoFlex className="cardContainer">
                  <Link to="/features#recruitmentRequests">
                    <SmallCardFeat blue icon={RecruitmentRequests} feat="Oportunidades" />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#schedule">
                    <SmallCardFeat blue icon={Schedule} feat="Calendário" />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#expenses">
                    <SmallCardFeat blue icon={Expenses} feat="Despesas" />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#timesheets">
                    <SmallCardFeat blue icon={Timesheets} feat="Timesheets" />
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          )}
          {this._isMobile && (
            <NanoFlex className="featsContainer featsMobile" flexDirection={"column"}>
              <NanoFlex className="headerWrapper">
                <Title orange title={this.translation.homepage.features.title} subtitle={this.translation.homepage.features.subtitle} />
              </NanoFlex>

              <NanoFlex className="infoWrapper">
                <Type.p>{this.translation.homepage.features.description}</Type.p>
              </NanoFlex>
              <NanoFlex className="buttonWrapper">
                <Link to="/features">
                  <ButtonCTA yellow label={this.translation.global.knowMore} />
                </Link>
              </NanoFlex>
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledFeaturesBlock>
    );
  }
}

export default FeaturesBlock;

// --- // Documentation // --- //
/*
  import FeaturesBlock from './FeaturesBlock.js';
  
  <FeaturesBlock />
  */
