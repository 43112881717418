import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";

//COMPONENTS
import Header from "./Header.js";
import Features from "./Features.js";
import PacksBlock from "./PacksBlock.js";
import VideoBlock from "../../Blocks/Video/Video.js";

const StyledRH = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.yellowPalette.default};
`;

export class RH extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledRH flexDirection={"column"}>
        <Header />
        <Features />
        <PacksBlock />
        <VideoBlock video="/videos/MLog.mp4" videoPoster="/videos/mlogPoster.png" title={this.translation.features.video.title} subtitle={this.translation.features.video.subtitle} yellow />
      </StyledRH>
    );
  }
}
