import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

// IMAGES
import { ReactComponent as MlogIcon } from "../../Global/icons/mlogIcon.svg";

const StyledCard = styled(NanoFlex)`
  width: auto;
  height: auto;
  margin: 8px;
  .cardWrapper {
    width: 260px;
    background-color: ${(props) => props.theme.color.greyPalette.white};
    border-radius: 40px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: ${(props) => (props.comingSoon ? "default" : "pointer")};
    transition: ${(props) => props.theme.transition};
    .headerCard {
      height: auto;
      padding: 24px;
      .iconHeader {
        svg {
          width: 40px;
          height: auto;
          .colorPoint {
            fill: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          }
        }
      }
      .titleHeader {
        margin: 16px 16px 0 16px;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
        padding-bottom: 24px;
        /* margin-bottom: 24px; */
        h3 {
          text-transform: uppercase;
          em {
            color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          }
        }
      }
    }
    .infoWrapper {
      padding: 0 24px 24px 24px;
      height: 148px;
      overflow: hidden;
      p {
        text-align: center;
        white-space: pre-line;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
      }
    }
    .buttonViewMore {
      height: auto;
      padding: 24px;
      h5 {
        transition: ${(props) => props.theme.transition};
        text-transform: uppercase;
        color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
      }
    }
    .bottomLine {
      width: 100%;
      height: 14px;
      background-color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
    }

    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35);
      transition: ${(props) => props.theme.transition};
      .buttonViewMore {
        h5 {
          font-style: ${(props) => (props.comingSoon ? "default" : "italic")};
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }
`;

class Card extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledCard flexDirection="column" blue={this.props.blue} yellow={this.props.yellow} orange={this.props.orange} comingSoon={this.props.comingSoon}>
        <NanoFlex className="cardWrapper" flexDirection="column">
          <NanoFlex className="headerCard" flexDirection="column">
            <NanoFlex className="iconHeader">
              <MlogIcon />
            </NanoFlex>
            <NanoFlex className="titleHeader">
              <Type.h3>
                <b>
                  Mlog
                  <em>.{this.props.pack}</em>
                </b>
              </Type.h3>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="infoWrapper" alignItems={"flex-start"}>
            <Type.p dangerouslySetInnerHTML={{ __html: this.props.info }}></Type.p>
          </NanoFlex>

          {!this.props.comingSoon && (
            <NanoFlex className="buttonViewMore" justifyContent={"flex-end"}>
              <Type.h5>
                <b>{this.translation.global.knowMore}</b>
              </Type.h5>
            </NanoFlex>
          )}

          {this.props.comingSoon && (
            <NanoFlex className="buttonViewMore" justifyContent={"flex-end"}>
              <Type.h5>
                <b>{this.translation.global.comingSoon}</b>
              </Type.h5>
            </NanoFlex>
          )}

          <NanoFlex className="bottomLine"></NanoFlex>
        </NanoFlex>
      </StyledCard>
    );
  }
}

// Set default props
// Card.defaultProps = {
//   pack: "",
//   info: "",
//   blue: false,
//   yellow: false,
//   orange: false,
//   comingSoon: false,
// };

export default Card;

// --- // Documentation // --- //
/*
  import Card from './Cards/Card.js';
  
  <Card pack="Lorem ipsum" info="Lorem ipsum" yellow/>
  */
