import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import ScrollspyNav from "react-scrollspy-nav";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import { Carousel } from "react-responsive-carousel";

// COMPONENTS
import ButtonCTA from "../../Buttons/ButtonCTA.js";
import Modal from "../../Blocks/Modals/Modal.js";
import ModalBudget from "../../Blocks/Modals/ModalBudget.js";

const StyledHeader = styled(NanoFlex)`
  /* height: auto; */
  position: relative;
  .headerContainer {
    background-color: ${(props) => props.theme.color.bluePalette.lightBlue};
    padding: 60px 60px 0 60px;
    .headerWrapper {
      background-color: ${(props) => props.theme.color.greyPalette.white};
      border-radius: 60px 60px 0 0;
      padding: 60px 120px;
      width: auto;
      .headerTitle {
        .titleWrapper {
          h2 {
            text-align: center;
            em {
              color: ${(props) => props.theme.color.bluePalette.blue};
            }
          }
        }
        .subTitleWrapper {
          margin: 16px 0;
          h4 {
            text-align: center;
            text-transform: uppercase;
          }
        }
      }

      .headerPrice {
        margin-top: 16px;
        .titlePrice {
          text-align: center;
        }
        .priceWrapper {
          margin: 16px 0;
          h1 {
            color: ${(props) => props.theme.color.bluePalette.blue};
            font-size: 120px;
            line-height: inherit;
          }
        }
        .priceSubtitle {
          h4 {
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .headerCtaContainer {
    padding: 24px 60px;
  }

  @media only screen and (max-width: 760px) {
    .headerContainer {
      padding: 40px 40px 0 40px;
      .headerWrapper {
        padding: 40px;
        .headerPrice {
          .priceWrapper {
            h1 {
              font-size: 80px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 520px) {
    .headerContainer {
      padding: 20px 20px 0 20px;
      .headerWrapper {
        padding: 32px;
        .headerPrice {
          .priceWrapper {
            h1 {
              font-size: 80px;
            }
          }
        }
      }
    }
  }
`;

class Header extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openFormBudget: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledHeader alignItems={"flex-start"} alignItems={"stretch"} flexDirection={"column"}>
        <NanoFlex className="headerContainer">
          <NanoFlex className="headerWrapper" flexDirection={"column"}>
            <NanoFlex className="headerTitle" flexDirection={"column"}>
              <NanoFlex className="titleWrapper">
                <Type.h2>
                  <strong>
                    Faça Match com o <em>MLog</em>
                  </strong>
                </Type.h2>
              </NanoFlex>
              <NanoFlex className="subTitleWrapper">
                <Type.h4>Descubra os nossos planos flexiveis e solicite uma demo sem compromisso</Type.h4>
              </NanoFlex>
            </NanoFlex>

            <NanoFlex className="headerPrice" flexDirection={"column"}>
              <NanoFlex className="titlePrice">
                <Type.h2>
                  <strong>A partir de</strong>
                </Type.h2>
              </NanoFlex>
              <NanoFlex className="priceWrapper" flexDirection={"column"}>
                <Type.h1>
                  <strong>33 €</strong>
                </Type.h1>
                <Type.p>utilizador/mês</Type.p>
              </NanoFlex>
              <NanoFlex className="priceSubtitle">
                <Type.h4>
                  <strong>até 10 utilizadores</strong>
                </Type.h4>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="headerCtaContainer">
          <ButtonCTA label={this.translation.global.aksForBudget} clickAction={() => this.setState({ openFormBudget: true })} />
          <Modal show={this.state.openFormBudget} content={<ModalBudget cancelAction={() => this.setState({ openFormBudget: false })} nextAction={() => this.setState({ openFormBudget: false })} />} />
        </NanoFlex>
      </StyledHeader>
    );
  }
}

export default Header;

// --- // Documentation // --- //
/*
  import Header from './Header.js';

  <Header />
  */
