import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";
import Type from "../Global/Typography.js";

const StyledInput = styled(NanoFlex)`
  height: auto;
  .title {
    margin-bottom: 2px;
    font-size: 14px;
    color: ${(props) => props.theme.color.greyPalette.darkGrey};
  }
  .inputWrapper {
    height: auto;
    input {
      border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
      padding: 8px;
      width: 100%;
      font-size: 14px;
      color: ${(props) => props.theme.color.greyPalette.darkGrey};
      font-family: ${(props) => props.theme.font};
      border-radius: 0 16px 16px 16px;
      &::placeholder {
        font-family: ${(props) => props.theme.font};
        color: ${(props) => props.theme.color.greyPalette.lightGrey};
      }
      &:focus {
        border: 1px solid ${(props) => props.theme.color.bluePalette.blue};
      }
    }
  }
`;

class Input extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.inputRef.current.value = this.props.defaultValue;
    }
  }

  render() {
    return (
      <StyledInput className="inputContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}
        <NanoFlex className="inputWrapper">
          <input
            ref={this.inputRef}
            type={this.props.title}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            onChange={(e) => {
              if (this.props.onChangeAction) {
                this.props.onChangeAction(e.target.value);
              }
            }}
            onKeyDown={(e) => {
              if (this.props.numeric) {
                var key = e.keyCode || e.which;
                if (
                  !(
                    (!e.shiftKey &&
                      !e.altKey &&
                      !e.ctrlKey &&
                      // numbers
                      key >= 48 &&
                      key <= 57) ||
                    // Numeric keypad
                    (key >= 96 && key <= 105) ||
                    // Backspace and Tab and Enter
                    key == 8 ||
                    key == 9 ||
                    key == 13 ||
                    // Home and End
                    key == 35 ||
                    key == 36 ||
                    // left and right arrows
                    key == 37 ||
                    key == 39 ||
                    // up and down arrows
                    key == 38 ||
                    key == 40 ||
                    // Del and Ins
                    key == 46 ||
                    key == 45
                  )
                ) {
                  // input is INVALID
                  e.returnValue = false;
                  if (e.preventDefault) e.preventDefault();
                }
              }
            }}
            {...(this.props.maxLength ? { maxLength: this.props.maxLength } : {})}></input>
        </NanoFlex>
      </StyledInput>
    );
  }
}

// Set default props
Input.defaultProps = {
  type: "text",
  title: "Title",
  placeholder: "placeholder",
};

export default Input;
