import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import { validateEmailFormat } from "../../Global/tools.js";

//COMPONENTS
import Input from "../../Forms/Input.js";
import Textarea from "../../Forms/Textarea.js";

import Checkbox from "../../Forms/Checkbox.js";
import ButtonCTA from "../../Buttons/ButtonCTA.js";

import Loading from "../Loading/Loading.js";

const StyledModalBudget = styled(NanoFlex)`
  height: auto;
  .contactBlock {
    .contactWrapper {
      .contactFormWrapper {
        width: auto;
        height: auto;
        .containerForm {
          width: 300px;
          background-color: ${(props) => props.theme.color.greyPalette.white};
          padding: 24px;
          border-radius: 24px;
          box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
          .titleFormWrapper {
            margin-bottom: 24px;
            padding-bottom: 16px;
            border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
            h4 {
              text-transform: uppercase;
              color: ${(props) => props.theme.color.bluePalette.blue};
            }
          }

          .formWrapper {
            height: auto;
            margin-bottom: 16px;
            .checkbox {
              margin-right: 8px;
            }
            a {
              text-decoration: none;
              color: ${(props) => props.theme.color.bluePalette.blue};
              font-weight: bold;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .btnsWrapper {
            margin-top: 24px;

            padding-top: 16px;
            border-top: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
            .cancelWrapper {
              width: auto;
              cursor: pointer;
              h5 {
                text-transform: uppercase;
              }
            }
            .cta {
              margin: 0;
              .ctaWrapper {
                margin-top: 0;
                padding: 6px 12px;
              }
            }
          }

          .loadingContainer {
            margin-top: 16px;
            height: 36px;
          }
        }
      }
    }
  }
`;

class ModalBudget extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      name: "",
      phone: "",
      email: "",
      observations: "",
      acceptedTerms: false,
      loading: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  isSendDisabled = () => {
    return this.state.name?.trim() == "" || this.state.email?.trim() == "" || validateEmailFormat(this.state.email?.trim()) == false || this.state.acceptedTerms == false;
  };

  sendRhBudgetEmail = () => {
    this.setState({ loading: true }, () => {
      fetch("api/Home/RhDemoEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...this.props.features,
          ...{
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            observations: this.state.observations,
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState(this.getDefaultState());
          this.props.nextAction();
        });
    });
  };

  render() {
    return (
      <StyledModalBudget>
        <NanoFlex className="contactBlock">
          <NanoFlex className="contactWrapper" alignItems={"flex-end"}>
            <NanoFlex className="contactFormWrapper" flexDirection={"column"}>
              <NanoFlex className="containerForm" flexDirection={"column"}>
                <NanoFlex className="titleFormWrapper">
                  <Type.h4>
                    <strong>{this.translation.global.askForDemo}</strong>
                  </Type.h4>
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Input
                    title="Nome"
                    type="text"
                    placeholder="Insira o seu Nome..."
                    defaultValue={this.state.name}
                    onChangeAction={(v) => {
                      this.setState({ name: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Input
                    numeric
                    maxLength={9}
                    title="Telemóvel"
                    type="text"
                    placeholder="Insira o seu número..."
                    defaultValue={this.state.phone}
                    onChangeAction={(v) => {
                      this.setState({ phone: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Input
                    title="Email"
                    type="text"
                    placeholder="Insira o seu email..."
                    defaultValue={this.state.email}
                    onChangeAction={(v) => {
                      this.setState({ email: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Textarea
                    title="Observações"
                    type="text"
                    placeholder="Indique as suas observações..."
                    defaultValue={this.state.observations}
                    onChangeAction={(v) => {
                      this.setState({ observations: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Checkbox
                    checked={this.state.acceptedTerms}
                    onCheckedChange={(v) => {
                      this.setState({ acceptedTerms: v });
                    }}
                  />
                  <Type.h6>
                    Li e concordo com os
                    <a href="https://www.marktest.com/wap/a/q/lang~pt/id~106.aspx" rel="noopener noreferrer" target="_blank">
                      {" "}
                      Termos e Condições
                    </a>{" "}
                    e com a
                    <a href="https://www.marktest.com/wap/a/q/lang~pt/id~106.aspx" rel="noopener noreferrer" target="_blank">
                      {" "}
                      Política de Privacidade
                    </a>
                  </Type.h6>
                </NanoFlex>
                {!this.state.loading && (
                  <NanoFlex className="formWrapper btnsWrapper" justifyContent={"space-between"}>
                    <NanoFlex className="cancelWrapper" onClick={this.props.cancelAction}>
                      <Type.h5>Cancelar</Type.h5>
                    </NanoFlex>
                    <ButtonCTA label="Solicitar Demo" disabled={this.isSendDisabled()} clickAction={this.sendRhBudgetEmail} />
                  </NanoFlex>
                )}
                {this.state.loading && (
                  <NanoFlex className="formWrapper loadingContainer" justifyContent={"space-between"}>
                    <Loading />
                  </NanoFlex>
                )}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledModalBudget>
    );
  }
}

export default ModalBudget;
