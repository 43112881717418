import React from "react";
// import { NavLink } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// IMAGES
import { ReactComponent as Powered } from "../../Global/images/poweredByMedialog.svg";

const StyledFooter = styled(NanoFlex)`
  height: auto;
  .footer {
    padding: 32px;
    background: ${(props) => props.theme.color.greyPalette.darkGrey};
    .mapListWrapper {
      .mapColumnList {
        width: auto;
        margin-right: 24px;
        .headerColumn {
          margin-bottom: 4px;
          height: auto;
          h6 {
            color: ${(props) => props.theme.color.greyPalette.white};
            text-transform: uppercase;
          }
        }
        .listContainer {
          height: auto;
          .itemContainer {
            height: auto;
            padding: 2px 0;
            margin-bottom: 2px;
            cursor: pointer;
            a {
              text-decoration: none;
            }
            h6 {
              color: ${(props) => props.theme.color.greyPalette.white};
            }
            &:last-child {
              padding: 2px 0 0 0;
              margin-bottom: 0px;
            }
            &:hover {
              h6 {
                text-decoration: underline;
              }
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .infoWrapper {
      .infoContainer {
        height: auto;
        margin-bottom: 8px;
        h6 {
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }
      .logoContainer {
        height: auto;
        svg {
          path {
            fill: ${(props) => props.theme.color.greyPalette.white};
          }
        }
      }
    }
    .whatsappContainer {
      position: fixed;
      width: 50px;
      height: 50px;
      bottom: 30px;
      right: 30px;
      background-color: #25d366;
      border-radius: 50px;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 8px 0px #474747;

      svg {
        height: 33px;
        fill: #ffffff;
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    .footer {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 550px) {
    .footer {
      .mapListWrapper {
        flex-direction: column;
        .mapColumnList {
          margin-bottom: 16px;
          margin-right: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .infoWrapper {
        margin-top: 16px;
      }
    }
  }
`;

class Footer extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledFooter>
        <NanoFlex className='footer' justifyContent={"space-between"}>
          <NanoFlex className='mapListWrapper' justifyContent={"flex-start"} alignItems={"flex-start"}>
            {/* COLUMN TO REPEAT */}
            <NanoFlex className='mapColumnList' flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
              <NanoFlex className='headerColumn' justifyContent={"flex-start"}>
                <Type.h6>
                  <b>{this.translation.global.features}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className='listContainer' flexDirection={"column"} alignItem={"flex-start"}>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#reports'>
                    <Type.h6>Reports</Type.h6>
                  </Link>
                </NanoFlex>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#candidates'>
                    <Type.h6>Candidatos</Type.h6>
                  </Link>
                </NanoFlex>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#consultants'>
                    <Type.h6>Colaboradores</Type.h6>
                  </Link>
                </NanoFlex>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#clients'>
                    <Type.h6>Clientes</Type.h6>
                  </Link>
                </NanoFlex>
                {/* PROJECTS - descomentar quando houver página*/}
                {/* <NanoFlex className="itemContainer" justifyContent={"flex-start"}>
                  <Link to="/features#projects">
                    <Type.h6>Projectos</Type.h6>
                  </Link>
                </NanoFlex> */}
                {/* END PROJECTS - descomentar quando houver página*/}
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#recruitmentRequests'>
                    <Type.h6>Oportunidades</Type.h6>
                  </Link>
                </NanoFlex>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#schedule'>
                    <Type.h6>Calendário</Type.h6>
                  </Link>
                </NanoFlex>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#expenses'>
                    <Type.h6>Despesas</Type.h6>
                  </Link>
                </NanoFlex>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/features#timesheets'>
                    <Type.h6>Timesheets</Type.h6>
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>

            {/* COLUMN TO REPEAT */}
            <NanoFlex className='mapColumnList' flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
              <NanoFlex className='headerColumn' justifyContent={"flex-start"}>
                <Type.h6>
                  <b>{this.translation.global.solutions}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className='listContainer' flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                {/* PREMIUM - descomentar quando houver página*/}
                {/* <NanoFlex className="itemContainer" justifyContent={"flex-start"}>
                  <Link to="/premium">
                    <Type.h6>MLog.Premium</Type.h6>
                  </Link>
                </NanoFlex> */}
                {/* END PREMIUM - descomentar quando houver página*/}
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <Link to='/rh'>
                    <Type.h6>MLog.RH</Type.h6>
                  </Link>
                </NanoFlex>
                {/* GP - descomentar quando houver página*/}
                {/* <NanoFlex className="itemContainer" justifyContent={"flex-start"}>
                  <Link to="/gp">
                    <Type.h6>MLog.GP</Type.h6>
                  </Link>
                </NanoFlex> */}
                {/* END GP - descomentar quando houver página*/}
              </NanoFlex>
            </NanoFlex>

            {/* SERVICES - descomentar quando houver página*/}
            {/* COLUMN TO REPEAT */}
            {/* <NanoFlex className="mapColumnList" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
              <NanoFlex className="headerColumn" justifyContent={"flex-start"}>
                <Type.h6>
                  <b>{this.translation.global.services}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className="listContainer" flexDirection={"column"} justifyContent={"flex-start"} alignItem={"flex-start"}>
                <NanoFlex className="itemContainer" justifyContent={"flex-start"}>
                  <Type.h6>Preços</Type.h6>
                </NanoFlex>
                <NanoFlex className="itemContainer" justifyContent={"flex-start"}>
                  <Type.h6>Manutenção</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex> */}
            {/* END SERVICES - descomentar quando houver página*/}

            {/* COLUMN TO REPEAT */}
            <NanoFlex className='mapColumnList' flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
              <NanoFlex className='headerColumn' justifyContent={"flex-start"}>
                <Type.h6>
                  <b>{this.translation.global.contacts}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className='listContainer' flexDirection={"column"} justifyContent={"flex-start"} alignItem={"flex-start"}>
                {/* FAQ - descomentar quando houver página*/}
                {/* <NanoFlex className="itemContainer" justifyContent={"flex-start"}>
                  <Type.h6>FAQ</Type.h6>
                </NanoFlex> */}
                {/* END FAQ - descomentar quando houver página*/}
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <a href='http://www.medialog.pt/' rel='noopener noreferrer' target='_blank'>
                    <Type.h6>Sobre a Medialog</Type.h6>
                  </a>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>

            {/* COLUMN TO REPEAT */}
            <NanoFlex className='mapColumnList' flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
              <NanoFlex className='headerColumn' justifyContent={"flex-start"}>
                <Type.h6>
                  <b>{this.translation.global.socialNetworks}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className='listContainer' flexDirection={"column"} alignItem={"flex-start"}>
                <NanoFlex className='itemContainer' justifyContent={"flex-start"}>
                  <a href='https://www.linkedin.com/company/mlogsoftware' rel='noopener noreferrer' target='_blank'>
                    <Type.h6>LinkedIn</Type.h6>
                  </a>
                </NanoFlex>
                {/* TWITTER - descomentar quando houver */}
                {/* <NanoFlex className="itemContainer" justifyContent={"flex-start"}>
                  <a href="#" rel="noopener noreferrer" target="_blank">
                    <Type.h6>Twitter</Type.h6>
                  </a>
                </NanoFlex> */}
                {/* END TWITTER - descomentar quando houver */}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className='infoWrapper' flexDirection={"column"} justifyContent={"flex-end"} alignItems={"flex-end"}>
            <NanoFlex className='infoContainer' justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Type.h6>© 2020 MEDIALOG</Type.h6>
            </NanoFlex>
            <NanoFlex className='logoContainer' justifyContent={"flex-end"} alignItems={"flex-end"}>
              <a href='http://www.medialog.pt/' rel='noopener noreferrer' target='_blank'>
                <Powered />
              </a>
            </NanoFlex>
          </NanoFlex>
          <div>
            <a class='whatsappContainer' href={`https://wa.me/${window.env.PHONE_NUMBER}`} target='_blank'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z' />
              </svg>
            </a>
          </div>
        </NanoFlex>
      </StyledFooter>
    );
  }
}

export default Footer;

// --- // Documentation // --- //
/*
  import Footer from './Shared/Footer.js';
  
  <Footer />
  */
