import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import FeaturesBlockDescription from "../../Blocks/Features/FeaturesBlockDescription.js";
import PacksBlock from "./PacksBlock.js";

// IMAGES
import Candidates from "../../Global/icons/candidates.svg";
import Clients from "../../Global/icons/clients.svg";
import Consultants from "../../Global/icons/consultants.svg";
import Expenses from "../../Global/icons/expenses.svg";
import Projects from "../../Global/icons/projects.svg";
import RecruitmentRequests from "../../Global/icons/recruitmentRequests.svg";
import Reports from "../../Global/icons/reports.svg";
import Schedule from "../../Global/icons/schedule.svg";
import Timesheets from "../../Global/icons/timesheets.svg";

const StyledFeaturesBlock = styled(NanoFlex)``;

class FeaturesBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledFeaturesBlock id="features">
        <NanoFlex className="featsBlockWrapper" flexDirection={"column"}>
          <NanoFlex className="blocksWrapper" flexDirection={"column"}>
            <FeaturesBlockDescription
              id="reports"
              blue
              icon={Reports}
              title={this.translation.features.featuresBlocks.reports.title}
              subtitle={this.translation.features.featuresBlocks.reports.subtitle}
              description={this.translation.features.featuresBlocks.reports.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />
            <FeaturesBlockDescription
              id="candidates"
              blue
              invert
              icon={Candidates}
              title={this.translation.features.featuresBlocks.candidates.title}
              subtitle={this.translation.features.featuresBlocks.candidates.subtitle}
              description={this.translation.features.featuresBlocks.candidates.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />
            <FeaturesBlockDescription
              id="consultants"
              blue
              icon={Consultants}
              title={this.translation.features.featuresBlocks.consultants.title}
              subtitle={this.translation.features.featuresBlocks.consultants.subtitle}
              description={this.translation.features.featuresBlocks.consultants.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />
            <FeaturesBlockDescription
              id="clients"
              blue
              invert
              icon={Clients}
              title={this.translation.features.featuresBlocks.clients.title}
              subtitle={this.translation.features.featuresBlocks.clients.subtitle}
              description={this.translation.features.featuresBlocks.clients.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />

            {/* PROJECTS - descomentar quando houver GP - Product Page */}
            {/* <FeaturesBlockDescription
              id="projects"
              blue
              icon={Projects}
              title={this.translation.features.featuresBlocks.projects.title}
              subtitle={this.translation.features.featuresBlocks.projects.subtitle}
              description={this.translation.features.featuresBlocks.projects.description.map((p) => {
                return <Type.p key={p}>{p}</Type.p>;
              })}
            /> */}
            {/* END PROJECTS - descomentar quando houver GP - Product Page */}
          </NanoFlex>

          {/* <NanoFlex className="blocksWrapper">
            <PacksBlock />
          </NanoFlex> */}

          <NanoFlex className="blocksWrapper" flexDirection={"column"}>
            <FeaturesBlockDescription
              id="recruitmentRequests"
              blue
              icon={RecruitmentRequests}
              title={this.translation.features.featuresBlocks.recruitmentRequests.title}
              subtitle={this.translation.features.featuresBlocks.recruitmentRequests.subtitle}
              description={this.translation.features.featuresBlocks.recruitmentRequests.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />
            <FeaturesBlockDescription
              id="schedule"
              blue
              invert
              icon={Schedule}
              title={this.translation.features.featuresBlocks.schedule.title}
              subtitle={this.translation.features.featuresBlocks.schedule.subtitle}
              description={this.translation.features.featuresBlocks.schedule.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />
            <FeaturesBlockDescription
              id="expenses"
              blue
              icon={Expenses}
              title={this.translation.features.featuresBlocks.expenses.title}
              subtitle={this.translation.features.featuresBlocks.expenses.subtitle}
              description={this.translation.features.featuresBlocks.expenses.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />
            <FeaturesBlockDescription
              id="timesheets"
              blue
              invert
              icon={Timesheets}
              title={this.translation.features.featuresBlocks.timesheets.title}
              subtitle={this.translation.features.featuresBlocks.timesheets.subtitle}
              description={this.translation.features.featuresBlocks.timesheets.description.map((p) => {
                return <Type.p key={p} dangerouslySetInnerHTML={{ __html: p }}></Type.p>;
              })}
            />
          </NanoFlex>
        </NanoFlex>
      </StyledFeaturesBlock>
    );
  }
}

export default FeaturesBlock;

// --- // Documentation // --- //
/*
    import FeaturesBlock from './FeaturesBlock.js';
    
    <FeaturesBlock />
    */
