import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

// IMAGES
import { ReactComponent as MlogIcon } from "../../Global/icons/mlogIcon.svg";

const StyledSmallCardPack = styled(NanoFlex)`
  width: auto;
  height: auto;
  margin: 8px;
  .cardWrapper {
    width: 240px;
    background-color: ${(props) => props.theme.color.greyPalette.white};
    border-radius: 32px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: ${(props) => (props.comingSoon ? "default" : "pointer")};
    transition: ${(props) => props.theme.transition};
    position: relative;
    .iconWrapper {
      width: auto;
      padding: 24px 16px;
      svg {
        width: 32px;
        height: auto;
        .colorPoint {
          fill: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
        }
      }
    }

    .headerCard {
      height: auto;
      padding: 24px 0;
      .titleHeader {
        h4 {
          text-transform: uppercase;
          em {
            color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          }
        }
      }
    }
    .buttonViewMore {
      height: auto;
      h6 {
        transition: ${(props) => props.theme.transition};
        text-transform: uppercase;
        color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
      }
    }

    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35);
      transition: ${(props) => props.theme.transition};
      .buttonViewMore {
        h6 {
          font-style: ${(props) => (props.comingSoon ? "default" : "italic")};
          transition: ${(props) => props.theme.transition};
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 8px;
      height: 100%;
      background-color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
    }
  }
`;

class SmallCardPack extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledSmallCardPack blue={this.props.blue} yellow={this.props.yellow} orange={this.props.orange} comingSoon={this.props.comingSoon}>
        <NanoFlex className="cardWrapper">
          <NanoFlex className="iconWrapper">
            <MlogIcon />
          </NanoFlex>

          <NanoFlex className="headerCard" flexDirection="column" justifyContent={"flex-start"} alignItems={"flex-start"}>
            <NanoFlex className="titleHeader" justifyContent={"flex-start"}>
              <Type.h4>
                <b>
                  Mlog
                  <em>.{this.props.pack}</em>
                </b>
              </Type.h4>
            </NanoFlex>
            {!this.props.comingSoon && (
              <NanoFlex className="buttonViewMore" justifyContent={"flex-start"}>
                <Type.h6>
                  <b>{this.translation.global.knowMore}</b>
                </Type.h6>
              </NanoFlex>
            )}

            {this.props.comingSoon && (
              <NanoFlex className="buttonViewMore" justifyContent={"flex-start"}>
                <Type.h6>
                  <b>{this.translation.global.comingSoon}</b>
                </Type.h6>
              </NanoFlex>
            )}
          </NanoFlex>
        </NanoFlex>
      </StyledSmallCardPack>
    );
  }
}

// Set default props
SmallCardPack.defaultProps = {
  pack: "",
  blue: false,
  yellow: false,
  orange: false,
  comingSoon: false,
};

export default SmallCardPack;

// --- // Documentation // --- //
/*
  import SmallCardPack from './Cards/SmallCardPack.js';
  
  <SmallCardPack pack="Lorem ipsum" blue/>
  */
