import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

// IMAGES
import { ReactComponent as Add } from "../../Global/icons/add.svg";

const StyledSmallCardFeat = styled(NanoFlex)`
  width: auto;
  height: auto;
  margin: 8px;
  .cardWrapper {
    width: 240px;
    background-color: ${(props) => (props.added ? props.theme.color.greyPalette.white : "rgba(227, 227, 227, 0.3)")};
    border-radius: 32px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: pointer;
    transition: ${(props) => props.theme.transition};
    position: relative;
    border: ${(props) => (props.added ? "none" : "2px dashed #E3E3E3")};
    .iconWrapper {
      width: auto;
      padding: 24px 16px;
      opacity: ${(props) => (props.added ? "1" : "0.6")};
      img {
        max-width: 32px;
        height: 32px;
      }
    }
    .headerCard {
      height: auto;
      padding: 24px 0;

      .titleHeader {
        opacity: ${(props) => (props.added ? "1" : "0.6")};
        h4 {
          text-transform: uppercase;
          em {
            color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          }
        }
      }
    }
    .buttonViewMore {
      height: auto;
      position: relative;
      h6 {
        transition: ${(props) => props.theme.transition};
        text-transform: uppercase;
        color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
      }
      .removeWrapper {
        width: auto;
        margin-right: 16px;
        h5 {
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }
    .addWrapper {
      .iconAddWrapper {
        width: auto;
        margin-right: 4px;
        svg {
          width: 8px;
          height: auto;
        }
      }
      h6 {
        b {
          color: ${(props) => props.theme.color.greyPalette.darkGrey};
        }
      }
    }

    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35);
      transition: ${(props) => props.theme.transition};
      .buttonViewMore {
        h6 {
          font-style: italic;
          transition: ${(props) => props.theme.transition};
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 8px;
      height: 100%;
      opacity: ${(props) => (props.added ? "1" : "0.6")};
      background-color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
    }
  }
`;

class SmallCardFeat extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledSmallCardFeat
        className={this.props.toAddCard && this.props.added == false ? "toAddCardWrapper" : ""}
        onClick={this.props.toAddCard && this.props.added == false ? this.props.onFeatToggle : null}
        blue={this.props.blue}
        yellow={this.props.yellow}
        orange={this.props.orange}
        added={this.props.added || !this.props.toAddCard}>
        <NanoFlex className="cardWrapper">
          <NanoFlex className="iconWrapper">
            <img src={this.props.icon} alt="icon" />
          </NanoFlex>

          <NanoFlex className="headerCard" flexDirection="column" justifyContent={"flex-start"} alignItems={"flex-start"}>
            <NanoFlex className="titleHeader" justifyContent={"flex-start"}>
              <Type.h4>
                <b>{this.props.feat}</b>
              </Type.h4>
            </NanoFlex>
            {(!this.props.toAddCard || this.props.added) && (
              <NanoFlex className="buttonViewMore" justifyContent={"flex-start"}>
                {this.props.toAddCard && (
                  <NanoFlex className="removeWrapper" onClick={this.props.onFeatToggle}>
                    <Type.h5>
                      <b>{this.translation.global.remove}</b>
                    </Type.h5>
                  </NanoFlex>
                )}
                <Type.h6>
                  <b>{this.translation.global.knowMore}</b>
                </Type.h6>
              </NanoFlex>
            )}
            {this.props.toAddCard && this.props.added == false && (
              <NanoFlex className="buttonViewMore addWrapper" justifyContent={"flex-start"}>
                <NanoFlex className="iconAddWrapper">
                  <Add />
                </NanoFlex>
                <Type.h6>
                  <b>{this.translation.global.add}</b>
                </Type.h6>
              </NanoFlex>
            )}
          </NanoFlex>
        </NanoFlex>
      </StyledSmallCardFeat>
    );
  }
}
// Set default props
SmallCardFeat.defaultProps = {
  icon: "", // Image Src
  feat: "",
};

export default SmallCardFeat;

// --- // Documentation // --- //
/*
  import SmallCardFeat from './Cards/SmallCardFeat.js';
  
  <SmallCardFeat icon={src} feat="Lorem ipsum"/>
  */
