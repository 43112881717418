import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

// IMAGES
import { ReactComponent as Add } from "../../Global/icons/add.svg";

const StyledCardFeat = styled(NanoFlex)`
  width: auto;
  height: auto;
  margin: 8px;
  .cardWrapper {
    width: 260px;
    background-color: ${(props) => (props.blue ? "rgba(227, 227, 227, 0.3)" : props.theme.color.greyPalette.white)};
    border-radius: 40px;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: pointer;
    transition: ${(props) => props.theme.transition};
    border: ${(props) => (props.added ? "none" : "2px dashed #E3E3E3")};
    position: relative;
    .headerCard {
      height: auto;
      padding: 24px;
      padding-bottom: ${(props) => (props.added ? "24px" : "8px")};
      opacity: ${(props) => (props.added ? "1" : "0.6")};
      .iconHeader {
        img {
          width: auto;
          max-width: 40px;
          height: 40px;
        }
      }
      .titleHeader {
        margin: 16px 16px 0 16px;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
        padding-bottom: 24px;
        h3 {
          text-transform: uppercase;
          em {
            color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
          }
        }
      }
    }
    .infoWrapper {
      padding: 0 24px 24px 24px;
      height: 148px;
      overflow: hidden;
      p {
        /* text-align: justify; */
        white-space: pre-line;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
      }
    }
    .buttonViewMore {
      height: auto;
      padding: 24px;
      h5 {
        transition: ${(props) => props.theme.transition};
        text-transform: uppercase;
        color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
      }
    }
    .addWrapper {
      height: 224px;
      .iconAddWrapper {
        height: auto;
        margin-bottom: 24px;
        svg {
          width: auto;
          height: 48px;
        }
      }
      .titleAddWrapper {
        height: auto;
        h4 {
          text-transform: uppercase;
        }
      }
    }
    .removeWrapper {
      position: absolute;
      left: 24px;
      bottom: 38px;
      height: auto;
      width: auto;
      h5 {
        text-transform: uppercase;
      }
    }
    .bottomLine {
      width: 100%;
      height: 14px;
      background-color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : props.theme.color.greyPalette.grey)};
      opacity: ${(props) => (props.added ? "1" : "0.6")};
    }

    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35);
      transition: ${(props) => props.theme.transition};
      .buttonViewMore {
        h5 {
          font-style: italic;
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }
`;

class CardFeat extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledCardFeat flexDirection="column" blue={this.props.blue} yellow={this.props.yellow} orange={this.props.orange} added={this.props.added || !this.props.toAddCard}>
        {(!this.props.toAddCard || this.props.added) && (
          <NanoFlex className="cardWrapper" flexDirection="column">
            <NanoFlex className="headerCard" flexDirection="column">
              <NanoFlex className="iconHeader">
                <img src={this.props.icon} alt="icon" />
              </NanoFlex>
              <NanoFlex className="titleHeader">
                <Type.h3>
                  <b>{this.props.feat}</b>
                </Type.h3>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="infoWrapper" alignItems={"flex-start"}>
              <Type.p dangerouslySetInnerHTML={{ __html: this.props.info }}></Type.p>
            </NanoFlex>
            <NanoFlex className="buttonViewMore" justifyContent={"flex-end"}>
              <Type.h5>
                <b>{this.translation.global.knowMore}</b>
              </Type.h5>
            </NanoFlex>
            {this.props.toAddCard && (
              <NanoFlex className="removeWrapper" onClick={this.props.onFeatToggle}>
                <Type.h5>
                  <b>{this.translation.global.remove}</b>
                </Type.h5>
              </NanoFlex>
            )}
            <NanoFlex className="bottomLine"></NanoFlex>
          </NanoFlex>
        )}

        {this.props.toAddCard && this.props.added == false && (
          <NanoFlex className="cardWrapper toAddCardWrapper" flexDirection="column" onClick={this.props.onFeatToggle}>
            <NanoFlex className="headerCard" flexDirection="column">
              <NanoFlex className="iconHeader">
                <img src={this.props.icon} alt="icon" />
              </NanoFlex>
              <NanoFlex className="titleHeader">
                <Type.h3>
                  <b>{this.props.feat}</b>
                </Type.h3>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="addWrapper" alignItems={"flex-start"} flexDirection="column">
              <NanoFlex className="iconAddWrapper">
                <Add />
              </NanoFlex>
              <NanoFlex className="titleAddWrapper">
                <Type.h4>{this.translation.global.add}</Type.h4>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="bottomLine"></NanoFlex>
          </NanoFlex>
        )}
      </StyledCardFeat>
    );
  }
}

// Set default props
CardFeat.defaultProps = {
  icon: "",
  feat: "",
  info: "",
};

export default CardFeat;

// --- // Documentation // --- //
/*
  import CardFeat from './Cards/CardFeat.js';
  
  <CardFeat icon={src} feat="Lorem ipsum" info="Lorem ipsum" yellow/>
  */
