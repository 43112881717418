import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import ScrollspyNav from "react-scrollspy-nav";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import { Carousel } from "react-responsive-carousel";

// COMPONENTS
import ButtonCTA from "../../Buttons/ButtonCTA.js";

// IMAGES
import { ReactComponent as RHmlogLogo } from "../../Global/images/RHmlogLogoSignature.svg";
import { ReactComponent as Illustration } from "../../Global/images/illustrationHeaderRH.svg";
import { ReactComponent as Powered } from "../../Global/images/poweredByMedialog.svg";

const StyledHeader = styled(NanoFlex)`
  /* height: auto; */
  position: relative;
  a {
    text-decoration: none;
  }
  .infoAside {
    height: auto;
    width: 50%;
    padding: 60px 100px 60px 60px;
    background-color: ${(props) => props.theme.color.greyPalette.white};
    .logoWrapper {
      width: 74%;
      svg {
        height: 100px;
        width: auto;
      }
    }
    .mainInfoWrapper {
      margin: 24px 0;
      width: 74%;
    }
    .logoPowered {
      height: auto;
      svg {
        height: auto;
        width: 100px;
        path {
          fill: ${(props) => props.theme.color.greyPalette.darkGrey};
        }
      }
    }
  }
  .illustrationWrapper {
    position: absolute;
    width: auto;
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    svg {
      width: auto;
      height: 100%;
    }
  }
  .sliderAside {
    position: relative;
    height: auto;
    width: 50%;

    .carouselWrapper {
      background-color: ${(props) => props.theme.color.yellowPalette.lightYellow};
      padding: 60px 60px 60px 160px;
      /* CAROUSEL */
      .carousel-root {
        width: 100%;
        height: 100%;
        .carousel-slider {
          width: 100%;
          height: 100%;
          .control-arrow {
            display: none;
          }
          .slider-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            .slider {
              width: 100%;
              padding: 42px 0;
              .slide {
                width: 100%;
                background-color: unset;
                .carouselItem {
                  width: 100%;
                }
              }
            }
          }
          .control-dots {
            position: absolute;
            bottom: 0;
            right: 0;
            width: auto;
            .dot {
              width: 20px;
              height: 8px;
              border-radius: 20px;
              margin: 0 4px;
              box-shadow: unset;
              background-color: ${(props) => props.theme.color.greyPalette.grey};
              &:hover {
                background-color: ${(props) => props.theme.color.greyPalette.darkGrey};
              }
            }
            .selected {
              pointer-events: none;
              background-color: ${(props) => props.theme.color.yellowPalette.yellow};
            }
          }
          .carousel-status {
            display: none;
          }
        }
        .carousel {
          .axis-vertical {
            display: none;
          }
        }
      }
      /* END CAROUSEL */
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    .infoAside {
      padding: 40px;
    }
    .illustrationWrapper {
      display: none;
    }
    .sliderAside {
      .carouselWrapper {
        padding: 40px;
      }
    }
  }

  @media only screen and (max-width: 760px) {
    flex-direction: column;
    .infoAside {
      width: 100%;
      .mainInfoWrapper {
        width: 100%;
      }
    }
    .sliderAside {
      width: 100%;
      .carouselWrapper {
        .carousel-root {
          .carousel-slider {
            .control-dots {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

class Header extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledHeader alignItems={"flex-start"} alignItems={"stretch"}>
        <NanoFlex className="infoAside" flexDirection={"column"} alignItems={"flex-start"} alignContent={"stretch"}>
          <NanoFlex className="logoWrapper" justifyContent={"flex-start"} alignItems={"flex-start"}>
            <RHmlogLogo />
          </NanoFlex>
          <NanoFlex className="mainInfoWrapper" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
            <Type.h1>
              <b>{this.translation.hr.header.slogan}</b>
            </Type.h1>
            <Type.h3>{this.translation.hr.header.signature}</Type.h3>
          </NanoFlex>
          <NanoFlex className="ctaWrapper">
            {/* <ScrollspyNav scrollTargetIds={["featuresRh"]} activeNavClass="activeNavItem" scrollDuration="100" offset={-124}>
              <ul>
                <li className="navItemWrapper">
                  <a href="#featuresRh">
                    <ButtonCTA yellow label={this.translation.global.knowMore} />
                  </a>
                </li>
              </ul>
            </ScrollspyNav> */}
            <Link to="rh#featuresRh">
              <ButtonCTA yellow label={this.translation.global.knowMore} />
            </Link>
          </NanoFlex>

          <NanoFlex className="logoPowered" justifyContent={"flex-end"}>
            <a href="http://www.medialog.pt/" rel="noopener noreferrer" target="_blank">
              <Powered />
            </a>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="illustrationWrapper">
          <Illustration />
        </NanoFlex>
        <NanoFlex className="sliderAside" alignItems={"stretch"} alignContent={"stretch"}>
          <NanoFlex className="carouselWrapper">
            <Carousel autoPlay>
              <NanoFlex className="carouselItem">
                <Type.h3>{this.translation.hr.header.benefits.benefit01}</Type.h3>
              </NanoFlex>
              <NanoFlex className="carouselItem">
                <Type.h3>{this.translation.hr.header.benefits.benefit02}</Type.h3>
              </NanoFlex>
              <NanoFlex className="carouselItem">
                <Type.h3>{this.translation.hr.header.benefits.benefit03}</Type.h3>
              </NanoFlex>
              <NanoFlex className="carouselItem">
                <Type.h3>{this.translation.hr.header.benefits.benefit04}</Type.h3>
              </NanoFlex>
              <NanoFlex className="carouselItem">
                <Type.h3>{this.translation.hr.header.benefits.benefit05}</Type.h3>
              </NanoFlex>
              <NanoFlex className="carouselItem">
                <Type.h3>{this.translation.hr.header.benefits.benefit06}</Type.h3>
              </NanoFlex>
              <NanoFlex className="carouselItem">
                <Type.h3>{this.translation.hr.header.benefits.benefit07}</Type.h3>
              </NanoFlex>
            </Carousel>
          </NanoFlex>
        </NanoFlex>
      </StyledHeader>
    );
  }
}

export default Header;

// --- // Documentation // --- //
/*
  import Header from './Header.js';

  <Header />
  */
