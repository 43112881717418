import React, { Component } from "react";
import "moment/locale/en-gb";
import t from "./Global/translate.json";

export default class BaseComponent extends Component {
  constructor(props) {
    super(props);

    this.setLanguage(this.getLanguage());

    this._isMobile = this.isMobile();
  }

  componentDidMount() {
    window.addEventListener("languageChange", this.onLanguageChange, false);
    window.addEventListener("resize", this.onResize, false);
  }

  onLanguageChange = (e) => {
    //Set Language
    this.setLanguage(e.detail);
    //Force Component Update
    this.forceUpdate();
  };

  onResize = (e) => {
    let newIsMobile = this.isMobile();
    if (newIsMobile !== this._isMobile) {
      //Set Is Mobile
      this._isMobile = newIsMobile;
      //Force Component Update
      this.forceUpdate();
      //Trigger onIsMobileChange
      window.dispatchEvent(new Event("isMobileChange"));
    }
  };

  componentWillUnmount() {
    window.removeEventListener("languageChange", this.onLanguageChange, false);
    window.removeEventListener("resize", this.onResize, false);
  }

  isMobile = () => {
    return window.innerWidth <= 1074;
  };

  setLanguage = (langId) => {
    let lang = null;
    switch (langId) {
      case 1:
        lang = "en";
        break;
      case 2:
        lang = "es";
        break;
      default:
        lang = "pt";
    }
    //Set Local Storage Language Key
    localStorage.setItem("language", lang);
    //Set Translation Variable used on the component
    this.translation = t[lang];
  };

  getLanguage = () => {
    let lang = localStorage.getItem("language");
    switch (lang) {
      case "en":
        return 1;
      case "es":
        return 2;
      default:
        return null;
    }
  };
}
