import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";

const StyledMenuBurguer = styled(NanoFlex)`
  width: auto;
  height: auto;
  .menuBurguer {
    width: 28px;
    height: 20px;
    cursor: pointer;
    .menuLine {
      height: 4px;
      background-color: ${(props) => props.theme.color.greyPalette.darkGrey};
      border-radius: 3px;
      transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
      &:first-child {
        animation: firstLinetoClose 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
      }
      &:last-child {
        animation: lastLinetoClose 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
      }
    }
  }
  .open {
    .menuLine {
      &:first-child {
        animation: firstLinetoOpen 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
        animation-fill-mode: forwards;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        animation: lastLinetoOpen 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
        animation-fill-mode: forwards;
      }
    }
  }

  @keyframes firstLinetoOpen {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(12px);
    }

    100% {
      transform: translateY(8px) rotate(45deg);
    }
  }

  @keyframes firstLinetoClose {
    0% {
      transform: translateY(8px) rotate(0);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes lastLinetoOpen {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-12px);
    }

    100% {
      transform: translateY(-8px) rotate(-45deg);
    }
  }

  @keyframes lastLinetoClose {
    0% {
      transform: translateY(-8px);
    }

    100% {
      transform: translateY(0px);
    }
  }
`;

class MenuBurguer extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open && this.props.onOpenChange) this.props.onOpenChange(this.state.open);

    if (this.props.open !== prevProps.open && this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
    }
  }

  onOpenClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <StyledMenuBurguer>
        <NanoFlex className={`menuBurguer ${this.state.open ? "open" : ""}`} flexDirection="column" justifyContent="space-between" onClick={() => this.onOpenClick()}>
          <NanoFlex className="menuLine"></NanoFlex>
          <NanoFlex className="menuLine"></NanoFlex>
          <NanoFlex className="menuLine"></NanoFlex>
        </NanoFlex>
      </StyledMenuBurguer>
    );
  }
}

export default MenuBurguer;

// --- // Documentation // --- //
/*
  import MenuBurguer from './Animations/MenuBurguer.js';
  
  <MenuBurguer />
  */
