import React from "react";
import styled from "styled-components";
import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

// IMAGES
import { ReactComponent as MlogIcon } from "../../Global/icons/mlogIcon.svg";

const StyledFeaturesBlockDescription = styled(NanoFlex)`
  .featureContainer {
    .iconAside {
      height: auto;
      width: 50%;
      padding: 40px;
      order: ${(props) => (props.invert ? "0" : "1")};
      img {
        height: 100%;
        width: auto;
        max-height: 180px;
      }
    }
    .descriptionAside {
      width: 50%;
      order: ${(props) => (props.invert ? "1" : "0")};
      padding: 60px;
      background-color: ${(props) => (props.blue ? props.theme.color.bluePalette.lighterBlue : props.yellow ? props.theme.color.yellowPalette.lightYellow : props.orange ? props.theme.color.orangePalette.lightOrange : props.theme.color.greyPalette.lighterGrey)};
      justify-content: ${(props) => (props.invert ? "flex-start" : "flex-end")};
      align-items: ${(props) => (props.invert ? "flex-start" : "flex-end")};
      .headerDescription {
        justify-content: ${(props) => (props.invert ? "flex-start" : "flex-end")};
        align-items: ${(props) => (props.invert ? "flex-start" : "flex-end")};
        margin-bottom: 16px;
        h2 {
          b {
            color: ${(props) => props.theme.color.bluePalette.blue};
          }
        }
        h3 {
          text-align: ${(props) => (props.invert ? "left" : "right")};
        }
      }
      .descriptionInfo {
        justify-content: ${(props) => (props.invert ? "flex-start" : "flex-end")};
        align-items: ${(props) => (props.invert ? "flex-start" : "flex-end")};
        p {
          text-align: ${(props) => (props.invert ? "left" : "right")};
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .featureContainer {
      flex-direction: column;
      .iconAside {
        order: 0;
        width: 100%;
        padding: 24px;
      }
      .descriptionAside {
        order: 1;
        width: 100%;
        padding: 24px;
      }
    }
  }
`;

const FeaturesBlockDescription = (props) => {
  return (
    <StyledFeaturesBlockDescription id={props.id} alignItems={"stretch"} invert={props.invert} blue={props.blue} yellow={props.yellow} orange={props.orange}>
      <NanoFlex className="featureContainer" alignItems={"stretch"} alignContent={"stretch"}>
        <NanoFlex className="iconAside">
          <img src={props.icon} alt="icon" />
        </NanoFlex>
        <NanoFlex className="descriptionAside" flexDirection={"column"} alignItems={"stretch"} alignContent={"stretch"}>
          <NanoFlex className="headerDescription" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
            <Type.h2>
              <b>{props.title}</b>
            </Type.h2>
            <Type.h3>{props.subtitle}</Type.h3>
          </NanoFlex>
          <NanoFlex className="descriptionInfo" flexDirection={"column"}>
            {props.description}
          </NanoFlex>
        </NanoFlex>
      </NanoFlex>
    </StyledFeaturesBlockDescription>
  );
};

// Set default props
FeaturesBlockDescription.defaultProps = {
  icon: "", // Image Src
  title: "",
  subtitle: "",
  description: "",
};

export default FeaturesBlockDescription;

// --- // Documentation // --- //
/*
  import FeaturesBlockDescription from './Features/FeaturesBlockDescription.js';
  
  <FeaturesBlockDescription icon={src} title="title" subtitle="subtitle" description="insert here the feature description"/>
  */
