import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import Title from "../../Blocks/Titles/Title.js";
import ButtonCTA from "../../Buttons/ButtonCTA.js";
import Switch from "../../Buttons/Switch.js";
import NavBar from "../Shared/NavBar.js";
import Input from "../../Forms/Input.js";
import Textarea from "../../Forms/Textarea.js";
import Checkbox from "../../Forms/Checkbox.js";
import Card from "../../Blocks/Cards/Card.js";
import SmallCardPack from "../../Blocks/Cards/SmallCardPack.js";
import SmallCardFeat from "../../Blocks/Cards/SmallCardFeat.js";
import CardFeat from "../../Blocks/Cards/CardFeat.js";
import CardPrice from "../../Blocks/Cards/CardPrice.js";
import VideoBlock from "../../Blocks/Video/Video.js";
import FeaturesBlockDescription from "../../Blocks/Features/FeaturesBlockDescription.js";
import Loading from "../../Blocks/Loading/Loading.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import Candidates from "../../Global/icons/candidates.svg";
import CandidatesYellow from "../../Global/icons/candidatesYellow.svg";
import Clients from "../../Global/icons/clients.svg";
import ClientsYellow from "../../Global/icons/clientsYellow.svg";
import ClientsOrange from "../../Global/icons/clientsOrange.svg";
import Consultants from "../../Global/icons/consultants.svg";
import ConsultantsYellow from "../../Global/icons/consultantsYellow.svg";
import ConsultantsOrange from "../../Global/icons/consultantsOrange.svg";
import Expenses from "../../Global/icons/expenses.svg";
import ExpensesYellow from "../../Global/icons/expensesYellow.svg";
import ExpensesOrange from "../../Global/icons/expensesOrange.svg";
import Projects from "../../Global/icons/projects.svg";
import ProjectsOrange from "../../Global/icons/projectsOrange.svg";
import RecruitmentRequests from "../../Global/icons/recruitmentRequests.svg";
import RecruitmentRequestsYellow from "../../Global/icons/recruitmentRequestsYellow.svg";
import Reports from "../../Global/icons/reports.svg";
import ReportsYellow from "../../Global/icons/reportsYellow.svg";
import ReportsOrange from "../../Global/icons/reportsOrange.svg";
import Schedule from "../../Global/icons/schedule.svg";
import ScheduleYellow from "../../Global/icons/scheduleYellow.svg";
import ScheduleOrange from "../../Global/icons/scheduleOrange.svg";
import Timesheets from "../../Global/icons/timesheets.svg";
import TimesheetsYellow from "../../Global/icons/timesheetsYellow.svg";
import TimesheetsOrange from "../../Global/icons/timesheetsOrange.svg";

const StyledGuidelines = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.yellowPalette.lightYellow};
  padding-top: 60px;
  .guidelineBlock {
    height: auto;
    padding: 62px;
  }
`;

export class Guidelines extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledGuidelines flexDirection={"column"} justifyContent={"flex-start"}>
        {/* MLOG WEBSITE GUIDELINES */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"}>
          <MlogLogo />
          <Type.h1>
            <b>GUIDELINES</b>
          </Type.h1>
        </NanoFlex>

        {/* TYPOGRAPHY */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Type.h1>
            <b>H1</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h1>
          <Type.h2>
            <b>H2</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h2>
          <Type.h3>
            <b>H3</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h3>
          <Type.h4>
            <b>H4</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h4>
          <Type.h5>
            <b>H5</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h5>
          <Type.h6>
            <b>H6</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h6>
          <Type.p>
            <b>P</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.p>
        </NanoFlex>

        {/* TITLES */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Title title="Lorem ipsum" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
          <Title left blue title="Lorem ipsum" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
          <Title right yellow title="Lorem ipsum" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
          <Title left orange title="Lorem ipsum" subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
        </NanoFlex>

        {/* BUTTONS */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <ButtonCTA label="Saiba mais" />
          <ButtonCTA yellow label="Saiba mais" />
          <ButtonCTA orange label="Saiba mais" />
        </NanoFlex>

        {/* BUTTONS */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Switch leftLabel="Mensal" rightLabel="Anual" />
        </NanoFlex>

        {/* NAVBAR */}
        <NanoFlex flexDirection={"column"} alignItems={"flex-start"}>
          <NavBar />
        </NanoFlex>

        {/* INPUT */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Input title="Nome" type="text" placeholder="Insira o seu Nome..." />
        </NanoFlex>

        {/* TEXTAREA */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Textarea title="Mensagem" placeholder="Insira uma mensagem..." />
        </NanoFlex>

        {/* CHECKBOX */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Checkbox />
        </NanoFlex>

        {/* CARD */}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <Card blue pack="Premium" info="Lorem ipsum dolor sit amet, t esse cillum dolore eu fugiat nulla pariatur." />
          <Card
            yellow
            pack="Rh"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          />
          <Card orange pack="Gp" info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure " />
        </NanoFlex>

        {/* SMALL CARD PACK */}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <SmallCardPack blue pack="Premium" />
          <SmallCardPack yellow pack="Rh" />
          <SmallCardPack orange pack="Gp" />
        </NanoFlex>

        {/* SMALL CARD FEATURE */}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <SmallCardFeat toAddCard icon={Reports} feat="reports" />
          <SmallCardFeat icon={Candidates} feat="Candidatos" />
          <SmallCardFeat icon={Consultants} feat="Consultores" />
        </NanoFlex>
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <SmallCardFeat icon={Clients} feat="Clientes" />
          <SmallCardFeat icon={Projects} feat="Projectos" />
          <SmallCardFeat icon={RecruitmentRequests} feat="Oportunidades" />
        </NanoFlex>
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <SmallCardFeat icon={Schedule} feat="Calendário" />
          <SmallCardFeat icon={Expenses} feat="Despesas" />
          <SmallCardFeat icon={Timesheets} feat="Timessheets" />
        </NanoFlex>

        {/* CARD FEATURE */}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <CardFeat
            toAddCard
            blue
            icon={Reports}
            feat="reports"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
          <CardFeat
            orange
            icon={Candidates}
            feat="Candidatos"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
          <CardFeat
            yellow
            icon={Consultants}
            feat="Consultores"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
        </NanoFlex>
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <CardFeat
            yellow
            icon={Clients}
            feat="Clientes"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
          <CardFeat
            blue
            icon={Projects}
            feat="Projectos"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
          <CardFeat
            orange
            icon={RecruitmentRequests}
            feat="Oportunidades"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
        </NanoFlex>
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <CardFeat
            blue
            icon={Schedule}
            feat="Calendário"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
          <CardFeat
            orange
            icon={Expenses}
            feat="Despesas"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
          <CardFeat
            yellow
            icon={Timesheets}
            feat="Timesheets"
            info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure "
          />
        </NanoFlex>

        {/* CARD PRICE */}
        <NanoFlex className="guidelineBlock" alignItems={"stretch"}>
          <CardPrice
            blue
            packLink="/premium"
            data={{
              pack: "Premium",
              oldTopPrice: "42€",
              newTopPrice: "25€",
              subtitleTopPrice: "Até 10 utilizadores",
              oldBottomPrice: "25€",
              newBottomPrice: "25€",
              subtitleBottomPrice: "Até 50 utilizadores",
              ctaPrice: "Pedir Demo",
              features: [
                {
                  icon: Reports,
                  name: "Reports",
                },
                {
                  icon: Candidates,
                  name: "Candidatos",
                },
                {
                  icon: Consultants,
                  name: "Colaboradores",
                },
                {
                  icon: Clients,
                  name: "Clientes",
                },
                {
                  icon: RecruitmentRequests,
                  name: "Oportunidades",
                },
                {
                  icon: Projects,
                  name: "Projectos",
                },
                {
                  icon: Schedule,
                  name: "Calendário",
                },
                {
                  icon: Expenses,
                  name: "Despesas",
                },
                {
                  icon: Timesheets,
                  name: "Timesheets",
                },
              ],
            }}
          />
          <CardPrice
            yellow
            packLink="/rh"
            data={{
              pack: "rh",
              oldTopPrice: "42€",
              newTopPrice: "25€",
              subtitleTopPrice: "Até 10 utilizadores",
              oldBottomPrice: "25€",
              newBottomPrice: "25€",
              subtitleBottomPrice: "Até 50 utilizadores",
              ctaPrice: "Pedir Demo",
              features: [
                {
                  icon: ReportsYellow,
                  name: "Reports",
                },
                {
                  icon: CandidatesYellow,
                  name: "Candidatos",
                },
                {
                  icon: ConsultantsYellow,
                  name: "Colaboradores",
                },
                {
                  icon: ClientsYellow,
                  name: "Clientes",
                },
                {
                  icon: RecruitmentRequestsYellow,
                  name: "Oportunidades",
                },
                {
                  icon: ScheduleYellow,
                  name: "Calendário",
                },
              ],
              featuresObservation: "Podem ser adicionadas novas features que não estejam incluídas no Pack.",
            }}
          />
          <CardPrice
            orange
            packLink="/gp"
            data={{
              pack: "gp",
              oldTopPrice: "42€",
              newTopPrice: "25€",
              subtitleTopPrice: "Até 10 utilizadores",
              oldBottomPrice: "25€",
              newBottomPrice: "25€",
              subtitleBottomPrice: "Até 50 utilizadores",
              ctaPrice: "Pedir Demo",
              features: [
                {
                  icon: ReportsOrange,
                  name: "Reports",
                },
                {
                  icon: ConsultantsOrange,
                  name: "Colaboradores",
                },
                {
                  icon: ClientsOrange,
                  name: "Clientes",
                },
                {
                  icon: ProjectsOrange,
                  name: "Projectos",
                },
                {
                  icon: ScheduleOrange,
                  name: "Calendário",
                },
                {
                  icon: TimesheetsOrange,
                  name: "Timesheets",
                },
              ],
              featuresObservation: "Podem ser adicionadas novas features que não estejam incluídas no Pack.",
            }}
          />
        </NanoFlex>

        {/* VIDEO BLOCK*/}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <VideoBlock src={"#"} title="teste" subtitle="teste 2" orange />
        </NanoFlex>

        {/* FEATURES DESCRIPTION BLOCK*/}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <FeaturesBlockDescription orange icon={Timesheets} title="title" subtitle="subtitle" description="insert here the feature description" />
          <FeaturesBlockDescription blue invert icon={Timesheets} title="title" subtitle="subtitle" description="insert here the feature description" />
          <FeaturesBlockDescription yellow icon={Timesheets} title="title" subtitle="subtitle" description="insert here the feature description" />
        </NanoFlex>

        {/* LOADING BLOCK*/}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Loading />
        </NanoFlex>
      </StyledGuidelines>
    );
  }
}
