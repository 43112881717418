import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import RenderRoute from "./components/Global/RenderRoute.js";
/* Themes */
import defaultTheme from "./themes/default.json";

/* Components */
import { Layout } from "./components/Views/Shared/Layout.js";
import { Guidelines } from "./components/Views/Guidelines/Guidelines.js";
import { Home } from "./components/Views/Home/Home.js";
import { RH } from "./components/Views/RH/RH.js";
import { Features } from "./components/Views/Features/Features.js";
import { Pricing } from "./components/Views/Pricing/Pricing.js";

// STYLES
import "./styles/reset.scss";
import "./styles/config.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Switch>
            {/* FEATURES */}
            <RenderRoute exact path="/features" title="MLog - Features">
              <Layout>
                <Features />
              </Layout>
            </RenderRoute>
            {/* SOLUCIONS */}
            {/* RH */}
            <RenderRoute exact path="/rh" title="MLog - RH">
              <Layout>
                <RH />
              </Layout>
            </RenderRoute>
            {/* SERVICES */}
            {/* <RenderRoute exact path="/services">
              <Layout>
                <Home />
              </Layout>
            </RenderRoute> */}

            {/* PRINCING */}
            <RenderRoute exact path="/pricing" title="MLog - Preços">
              <Layout>
                <Pricing />
              </Layout>
            </RenderRoute>

            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/guidelines">
                <Guidelines />
              </RenderRoute>
            )}

            {/* HOME */}
            <RenderRoute path="*" title="Software de Recursos Humanos - MLog">
              <Layout>
                <Home />
              </Layout>
            </RenderRoute>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
