import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex";
import Type from "../Global/Typography.js";

const StyledTextarea = styled(NanoFlex)`
  .title {
    margin-bottom: 2px;
    font-size: 14px;
    color: ${(props) => props.theme.color.greyPalette.darkGrey};
  }
  textarea {
    border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
    padding: 8px;
    width: 100%;
    height: 100px;
    font-size: 14px;
    color: ${(props) => props.theme.color.greyPalette.darkGrey};
    font-family: ${(props) => props.theme.font};
    border-radius: 8px;
    resize: none;
    &::placeholder {
      font-family: ${(props) => props.theme.font};
      color: ${(props) => props.theme.color.greyPalette.lightGrey};
    }
    &:focus {
      border: 1px solid ${(props) => props.theme.color.bluePalette.blue};
    }
  }
`;

class Textarea extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.inputRef.current.value = this.props.defaultValue;
    }
  }

  render() {
    return (
      <StyledTextarea className="TextAreaContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}
        <NanoFlex className="TextAreaWrapper">
          <textarea
            ref={this.inputRef}
            type={this.props.title}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            onChange={(e) => {
              if (this.props.onChangeAction) {
                this.props.onChangeAction(e.target.value);
              }
            }}></textarea>
        </NanoFlex>
      </StyledTextarea>
    );
  }
}

// Set default props
Textarea.defaultProps = {
  title: "Title",
  placeholder: "placeholder",
};

export default Textarea;
