import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import { elementMatchesAny } from "../../Global/tools.js";

// COMPONENTS
import ButtonCTA from "../../Buttons/ButtonCTA.js";
import CardFeat from "../../Blocks/Cards/CardFeat.js";
import SmallCardFeat from "../../Blocks/Cards/SmallCardFeat.js";
import Modal from "../../Blocks/Modals/Modal.js";
import ModalBudget from "../../Blocks/Modals/ModalBudget.js";
import ModalDemo from "../../Blocks/Modals/ModalDemo.js";

// IMAGES
import Candidates from "../../Global/icons/candidatesYellow.svg";
import Clients from "../../Global/icons/clientsYellow.svg";
import Consultants from "../../Global/icons/consultantsYellow.svg";
import Expenses from "../../Global/icons/expensesYellow.svg";
import RecruitmentRequests from "../../Global/icons/recruitmentRequestsYellow.svg";
import Reports from "../../Global/icons/reportsYellow.svg";
import Schedule from "../../Global/icons/scheduleYellow.svg";
import Timesheets from "../../Global/icons/timesheetsYellow.svg";

const StyledFeatures = styled(NanoFlex)`
  height: auto;
  .featuresContainer {
    max-width: 70vw;
    padding: 40px 0px;
    .featsContainer {
      .featsList {
        .cardContainer {
          width: auto;
          a {
            text-decoration: none;
          }
        }
      }
    }
    .ctaWrapper {
      margin-top: 16px;
    }
  }
`;

class Features extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasExpenses: false,
      hasTimesheets: false,
      openFormBudget: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledFeatures id="featuresRh">
        <NanoFlex className="featuresContainer" flexDirection={"column"}>
          {!this._isMobile && (
            <NanoFlex className="featsContainer" flexDirection={"column"}>
              <NanoFlex className="featsList" flexWrap={"wrap"}>
                <NanoFlex className="cardContainer">
                  <Link to="/features#reports">
                    <CardFeat yellow icon={Reports} feat={this.translation.hr.features.reports.title} info={this.translation.hr.features.reports.description} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#candidates">
                    <CardFeat yellow icon={Candidates} feat={this.translation.hr.features.candidates.title} info={this.translation.hr.features.candidates.description} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#consultants">
                    <CardFeat yellow icon={Consultants} feat={this.translation.hr.features.consultants.title} info={this.translation.hr.features.consultants.description} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#clients">
                    <CardFeat yellow icon={Clients} feat={this.translation.hr.features.clients.title} info={this.translation.hr.features.clients.description} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#recruitmentRequests">
                    <CardFeat yellow icon={RecruitmentRequests} feat={this.translation.hr.features.recruitmentRequests.title} info={this.translation.hr.features.recruitmentRequests.description} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#schedule">
                    <CardFeat yellow icon={Schedule} feat={this.translation.hr.features.schedule.title} info={this.translation.hr.features.schedule.description} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link
                    to="/features#expenses"
                    onClick={(e) => {
                      if (elementMatchesAny(e.target, [".toAddCardWrapper", ".removeWrapper"])) {
                        e.preventDefault();
                        return;
                      }
                    }}>
                    <CardFeat
                      toAddCard
                      added={this.state.hasExpenses}
                      yellow
                      icon={Expenses}
                      feat={this.translation.hr.features.expenses.title}
                      info={this.translation.hr.features.expenses.description}
                      onFeatToggle={() => {
                        this.setState({ hasExpenses: !this.state.hasExpenses });
                      }}
                    />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link
                    to="/features#timesheets"
                    onClick={(e) => {
                      if (elementMatchesAny(e.target, [".toAddCardWrapper", ".removeWrapper"])) {
                        e.preventDefault();
                        return;
                      }
                    }}>
                    <CardFeat
                      toAddCard
                      added={this.state.hasTimesheets}
                      yellow
                      icon={Timesheets}
                      feat={this.translation.hr.features.timesheets.title}
                      info={this.translation.hr.features.timesheets.description}
                      onFeatToggle={() => {
                        this.setState({ hasTimesheets: !this.state.hasTimesheets });
                      }}
                    />
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          )}
          {this._isMobile && (
            <NanoFlex className="featsContainer featsMobile" flexDirection={"column"}>
              <NanoFlex className="featsList" flexWrap={"wrap"}>
                <NanoFlex className="cardContainer">
                  <Link to="/features#reports">
                    <SmallCardFeat yellow icon={Reports} feat={this.translation.hr.features.reports.title} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#candidates">
                    <SmallCardFeat yellow icon={Candidates} feat={this.translation.hr.features.candidates.title} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#consultants">
                    <SmallCardFeat yellow icon={Consultants} feat={this.translation.hr.features.consultants.title} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#clients">
                    <SmallCardFeat yellow icon={Clients} feat={this.translation.hr.features.clients.title} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#recruitmentRequests">
                    <SmallCardFeat yellow icon={RecruitmentRequests} feat={this.translation.hr.features.recruitmentRequests.title} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link to="/features#schedule">
                    <SmallCardFeat yellow icon={Schedule} feat={this.translation.hr.features.schedule.title} />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link
                    to="/features#expenses"
                    onClick={(e) => {
                      if (elementMatchesAny(e.target, [".toAddCardWrapper", ".removeWrapper"])) {
                        e.preventDefault();
                        return;
                      }
                    }}>
                    <SmallCardFeat
                      toAddCard
                      added={this.state.hasExpenses}
                      yellow
                      icon={Expenses}
                      feat={this.translation.hr.features.expenses.title}
                      onFeatToggle={() => {
                        this.setState({ hasExpenses: !this.state.hasExpenses });
                      }}
                    />
                  </Link>
                </NanoFlex>
                <NanoFlex className="cardContainer">
                  <Link
                    to="/features#timesheets"
                    onClick={(e) => {
                      if (elementMatchesAny(e.target, [".toAddCardWrapper", ".removeWrapper"])) {
                        e.preventDefault();
                        return;
                      }
                    }}>
                    <SmallCardFeat
                      toAddCard
                      added={this.state.hasTimesheets}
                      yellow
                      icon={Timesheets}
                      feat={this.translation.hr.features.timesheets.title}
                      onFeatToggle={() => {
                        this.setState({ hasTimesheets: !this.state.hasTimesheets });
                      }}
                    />
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          )}
          <NanoFlex className="ctaWrapper">
            <ButtonCTA label={this.translation.global.askForDemo} clickAction={() => this.setState({ openFormBudget: true })} />
            <Modal
              show={this.state.openFormBudget}
              content={
                <ModalDemo
                  features={{
                    hasExpenses: this.state.hasExpenses,
                    hasTimesheets: this.state.hasTimesheets,
                  }}
                  cancelAction={() => this.setState({ openFormBudget: false })}
                  nextAction={() => this.setState({ openFormBudget: false })}
                />
              }
            />
          </NanoFlex>
        </NanoFlex>
      </StyledFeatures>
    );
  }
}

export default Features;

// --- // Documentation // --- //
/*
    import Features from './Features.js';
  
    <Features />
    */
