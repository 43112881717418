import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import { validateEmailFormat } from "../../Global/tools.js";

//COMPONENTS
import Input from "../../Forms/Input.js";
import Textarea from "../../Forms/Textarea.js";
import Checkbox from "../../Forms/Checkbox.js";
import ButtonCTA from "../../Buttons/ButtonCTA.js";

//IMAGES
import MapLocation from "../../Global/images/mapLocation.png";
import IllustrationContact from "../../Global/images/illustrationContact.svg";

import Loading from "../../Blocks/Loading/Loading.js";

const StyledContactsBlock = styled(NanoFlex)`
  height: auto;
  .contactBlock {
    padding: 32px;
    background: rgba(245, 246, 247, 0.5);
    .addressWrapper {
      .addressContainer {
        width: auto;
        h3 {
          text-transform: uppercase;
          margin-bottom: 8px;
        }
        .addressInfo {
          margin-bottom: 8px;
          height: auto;
          a {
            text-decoration: none;
            p {
              &:hover {
                text-decoration: underline;
              }
            }

            .mapContainer {
              border-radius: 20px;
              overflow: hidden;
              width: 250px;
              height: 200px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
            margin-top: 16px;
          }
        }
      }
    }
    .contactWrapper {
      .illustrationContainer {
        width: auto;
        img {
          height: 450px;
        }
      }
      .contactFormWrapper {
        width: auto;
        height: auto;
        .containerForm {
          width: 300px;
          background-color: ${(props) => props.theme.color.greyPalette.white};
          padding: 24px;
          border-radius: 24px;
          box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
          .formWrapper {
            height: auto;
            margin-bottom: 8px;
            .checkbox {
              margin-right: 8px;
            }
            a {
              text-decoration: none;
              color: ${(props) => props.theme.color.bluePalette.blue};
              font-weight: bold;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          .loadingContainer {
            margin-top: 16px;
            height: 36px;
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 800px) {
    .contactBlock {
      .contactWrapper {
        .illustrationContainer {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 670px) {
    .contactBlock {
      flex-direction: column;
      .addressWrapper {
        order: 2;
        margin-top: 24px;
      }
      .contactWrapper {
        order: 1;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    .contactBlock {
      .addressWrapper {
        width: 85vw;
        .addressContainer {
          .addressInfo {
            a {
              .mapContainer {
                width: 100%;
              }
            }
          }
        }
      }
      .contactWrapper {
        .contactFormWrapper {
          .containerForm {
            width: 85vw;
          }
        }
      }
    }
  }
`;

class ContactsBlock extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
      acceptedTerms: false,
      loading: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  isSendDisabled = () => {
    return this.state.name?.trim() == "" || this.state.email?.trim() == "" || validateEmailFormat(this.state.email?.trim()) == false || this.state.subject?.trim() == "" || this.state.message?.trim() == "" || this.state.acceptedTerms == false;
  };

  sendContactUsEmail = () => {
    this.setState({ loading: true }, () => {
      fetch("api/Home/ContactUsEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          subject: this.state.subject,
          message: this.state.message,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState(this.getDefaultState());
        });
    });
  };

  render() {
    return (
      <StyledContactsBlock id="contacts">
        <NanoFlex className="contactBlock">
          <NanoFlex className="addressWrapper">
            <NanoFlex className="addressContainer" flexDirection={"column"} alignItems={"flex-start"}>
              <Type.h3>
                <b>{this.translation.global.contacts}</b>
              </Type.h3>
              <NanoFlex className="addressInfo" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                <Type.p>Rua Padre Luís Aparício, nº 10, 4º</Type.p>
                <Type.p>1150 248 Lisboa</Type.p>
              </NanoFlex>
              <NanoFlex className="addressInfo" justifyContent={"flex-start"} alignItems={"flex-start"}>
                <Type.p>+351 214 849 400</Type.p>
              </NanoFlex>
              <NanoFlex className="addressInfo" justifyContent={"flex-start"} alignItems={"flex-start"}>
                <a href="mailto:mlog@medialog.pt">
                  <Type.p>mlog@medialog.pt</Type.p>
                </a>
                <a href="mailto="></a>
              </NanoFlex>
              <NanoFlex className="addressInfo" justifyContent={"flex-start"} alignItems={"flex-start"}>
                <a href="https://goo.gl/maps/anMmUD9NG3N2" rel="noopener noreferrer" target="_blank">
                  <NanoFlex className="mapContainer">
                    <img src={MapLocation} alt="Map Location" />
                  </NanoFlex>
                </a>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="contactWrapper" alignItems={"flex-end"}>
            <NanoFlex className="illustrationContainer">
              <img src={IllustrationContact} />
            </NanoFlex>
            <NanoFlex className="contactFormWrapper">
              <NanoFlex className="containerForm" flexDirection={"column"}>
                <NanoFlex className="formWrapper">
                  <Input
                    title="Nome"
                    type="text"
                    placeholder="Insira o seu Nome..."
                    defaultValue={this.state.name}
                    onChangeAction={(v) => {
                      this.setState({ name: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Input
                    numeric
                    maxLength={9}
                    title="Telemóvel"
                    type="text"
                    placeholder="Insira o seu número..."
                    defaultValue={this.state.phone}
                    onChangeAction={(v) => {
                      this.setState({ phone: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Input
                    title="Email"
                    type="text"
                    placeholder="Insira o seu email..."
                    defaultValue={this.state.email}
                    onChangeAction={(v) => {
                      this.setState({ email: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Input
                    title="Assunto"
                    type="text"
                    placeholder="Insira o assunto..."
                    defaultValue={this.state.subject}
                    onChangeAction={(v) => {
                      this.setState({ subject: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Textarea
                    title="Mensagem"
                    placeholder="Insira uma mensagem..."
                    defaultValue={this.state.message}
                    onChangeAction={(v) => {
                      this.setState({ message: v });
                    }}
                  />
                </NanoFlex>
                <NanoFlex className="formWrapper">
                  <Checkbox
                    checked={this.state.acceptedTerms}
                    onCheckedChange={(v) => {
                      this.setState({ acceptedTerms: v });
                    }}
                  />
                  <Type.h6>
                    Li e concordo com os
                    <a href="https://www.marktest.com/wap/a/q/lang~pt/id~106.aspx" rel="noopener noreferrer" target="_blank">
                      {" "}
                      Termos e Condições
                    </a>{" "}
                    e com a
                    <a href="https://www.marktest.com/wap/a/q/lang~pt/id~106.aspx" rel="noopener noreferrer" target="_blank">
                      {" "}
                      Política de Privacidade
                    </a>
                  </Type.h6>
                </NanoFlex>
                {!this.state.loading && (
                  <NanoFlex className="formWrapper">
                    <ButtonCTA label="Enviar" disabled={this.isSendDisabled()} clickAction={this.sendContactUsEmail} />
                  </NanoFlex>
                )}
                {this.state.loading && (
                  <NanoFlex className="formWrapper loadingContainer">
                    <Loading />
                  </NanoFlex>
                )}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledContactsBlock>
    );
  }
}

export default ContactsBlock;

// --- // Documentation // --- //
/*
    import ContactsBlock from './Shared/ContactsBlock.js';
    
    <ContactsBlock />
    */
