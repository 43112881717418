import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";

//IMAGES
import { ReactComponent as Check } from "../Global/icons/check.svg";

const StyledCheckbox = styled(NanoFlex)`
  width: auto;
  .checkboxWrapper {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.color.greyPalette.grey};
    cursor: pointer;
    padding-left: 1px;
    svg {
      path {
        fill: ${(props) => props.theme.color.greyPalette.lightGrey};
      }
    }
    &:hover {
      border: 1px solid ${(props) => props.theme.color.bluePalette.blue};
    }
  }
  .checked {
    border: 1px solid ${(props) => props.theme.color.bluePalette.blue};
    svg {
      path {
        fill: ${(props) => props.theme.color.bluePalette.blue};
      }
    }
  }
`;

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked ?? false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked ?? false });
    }

    if (this.state.checked !== prevState.checked && this.props.onCheckedChange) {
      this.props.onCheckedChange(this.state.checked);
    }
  }

  toggleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  render() {
    return (
      <StyledCheckbox className="checkbox">
        <NanoFlex className={`checkboxWrapper ${this.state.checked ? "checked" : ""}`} onClick={this.toggleCheck}>
          <Check />
        </NanoFlex>
      </StyledCheckbox>
    );
  }
}

// Set default props
Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
