import React from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

// COMPONENTS
import Title from "../../Blocks/Titles/Title.js";

// IMAGES
import { ReactComponent as BackgroundVideo1 } from "../../Global/images/bckVideo1.svg";
import { ReactComponent as BackgroundVideo2 } from "../../Global/images/bckVideo2.svg";

const StyledVideoBlock = styled(NanoFlex)`
  padding: 40px;
  overflow: hidden;
  .headerVideo {
    width: auto;
    height: auto;
    justify-content: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
    align-items: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
    margin-bottom: 24px;
    .titleWrapper {
      justify-content: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
      align-items: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
      margin-bottom: 8px;
      text-align: ${(props) => (props.left ? "left" : props.right ? "right" : "center")};

      .title {
        color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : "")};
        text-transform: uppercase;
        b {
          color: ${(props) => (props.blue ? props.theme.color.bluePalette.blue : props.yellow ? props.theme.color.yellowPalette.yellow : props.orange ? props.theme.color.orangePalette.orange : "")};
        }
      }
    }

    .subtitlteWrapper {
      justify-content: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
      align-items: ${(props) => (props.left ? "flex-start" : props.right ? "flex-end" : "")};
      text-align: ${(props) => (props.left ? "left" : props.right ? "right" : "center")};

      .subtitle {
        text-transform: uppercase;
      }
    }
  }

  .videoWrapper {
    width: 60%;
    position: relative;
    .videoContainer {
      width: 100%;
      overflow: hidden;
      background-color: ${(props) => props.theme.color.bluePalette.darkBlue};
      position: relative;
      padding-bottom: calc(52.4633%);
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .backgroundTopWrapper {
      height: auto;
      width: 50%;
      position: absolute;
      left: -20%;
      top: -16%;
      z-index: -1;
      svg {
        width: 80%;
      }
    }
    .backgroundBottomWrapper {
      height: auto;
      width: 80%;
      position: absolute;
      right: -30%;
      bottom: -50%;
      z-index: -1;
      svg {
        width: 80%;
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    padding: 40px 20px;
    .videoWrapper {
      width: 100%;
    }
  }
`;
const VideoBlock = (props) => {
  return (
    <StyledVideoBlock id="videoBlock" flexDirection="column" left={props.left} right={props.right} blue={props.blue} yellow={props.yellow} orange={props.orange}>
      <NanoFlex className="headerVideo">
        <Title title={props.title} subtitle={props.subtitle} />
      </NanoFlex>
      <NanoFlex className="videoWrapper">
        <NanoFlex className="backgroundTopWrapper">
          <BackgroundVideo1 />
        </NanoFlex>
        <div className="videoContainer">
          <video controls poster={props.videoPoster}>
            <source src={props.video} type="video/mp4" />
          </video>
        </div>
        <NanoFlex className="backgroundBottomWrapper">
          <BackgroundVideo2 />
        </NanoFlex>
      </NanoFlex>
    </StyledVideoBlock>
  );
};

// Set default props
VideoBlock.defaultProps = {
  video: "", // Video Src
  title: "",
  subtitle: "",
};

export default VideoBlock;
